import * as React from "react";
import { Outlet } from "react-router-dom";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import NavBar from './components/NavBar';
import Copyright from './components/Copyright';

import "./layout.scss";

const TRIGGER_THRESHOLD = 40;

export default function Layout() {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: TRIGGER_THRESHOLD,
    });

    return (
        <div>
            <NavBar trigger={trigger} />

            <div className={`content-wrapper ${trigger && "nav-bar-scrolled"}`}>
                <Outlet />
            </div>

            <Copyright />
        </div>
    );
}
