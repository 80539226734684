import React from "react";
import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import CampaignIcon from "@mui/icons-material/Campaign";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import GavelIcon from "@mui/icons-material/Gavel";
import Groups3Icon from "@mui/icons-material/Groups3";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ROICalculator from "../components/ROICalculator";

import templatesFeatureGraphic from "../assets/bp-templates.png";
import signaturesFeatureGraphic from "../assets/bp-signatures.png";
import oneClickToggle from '../assets/bp-one-click2.png';
import howItWorksGraphic from '../assets/how-it-works.gif';
import benefitsGraphic from '../assets/bp-benefits-graphic.png';

import "./BusinessPro.scss";

function Metadata() {
    return (
        <Helmet>
            <title>Toggles | Boost Team Email Productivity with Business Pro</title>
            <meta
                name="description"
                content="Streamline workflows, automate team-wide email tasks, and enhance productivity across your business with our Business Pro plan. Start boosting team efficiency today!"
            />
            <meta
                itemProp="name"
                content="Toggles | Boost Team Email Productivity with Business Pro"
            />
            <meta
                itemProp="description"
                content="Streamline workflows, automate team-wide email tasks, and enhance productivity across your business with our Business Pro plan. Start boosting team efficiency today!"
            />
            <meta
                itemProp="image"
                content="http://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta property="og:url" content="https://www.gettoggles.com" />
            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content="Toggles | Boost Team Email Productivity with Business Pro"
            />
            <meta
                property="og:description"
                content="Streamline workflows, automate team-wide email tasks, and enhance productivity across your business with our Business Pro plan. Start boosting team efficiency today!"
            />
            <meta
                property="og:image"
                content="https://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta name="twitter:site" content="@togglesforemail" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content="Toggles | Boost Team Email Productivity with Business Pro"
            />
            <meta
                name="twitter:description"
                content="Streamline workflows, automate team-wide email tasks, and enhance productivity across your business with our Business Pro plan. Start boosting team efficiency today!"
            />
            <meta
                name="twitter:image"
                content="https://www.gettoggles.com/graphics/meta-twitter-image-1600x900.jpg"
            />
            <link rel="canonical" href="https://www.gettoggles.com/" />
        </Helmet>
    );
}

export default function BusinessPro() {
    return (
        <Container maxWidth="xl">
            <Metadata />
            <div className="bp-hero">
                <Box className="hero-box">
                    <Typography
                        className="hero-title animated-color"
                        variant="h1"
                        align="center"
                    >
                        Companies work faster with Toggles
                    </Typography>
                    <Typography
                        className="hero-subtitle"
                        variant="h5"
                        align="center"
                    >
                        Automate repetitive emails and cut time to send by
                        nearly 3X for a more efficient, unified workflow.
                    </Typography>
                    <Button
                        className="hero-button"
                        variant="outlined"
                        size="large"
                        href="https://hub.togglesforemail.com/signup?type=business"
                    >
                        <span className="animated-color">
                            Get Toggles Business Pro
                        </span>
                    </Button>
                    <div className="hero-graphic">
                        <img
                            src={howItWorksGraphic}
                            alt="How Toggles for Outlook works"
                        />
                    </div>
                </Box>
            </div>
            <div className="section feature darkest full-width">
                <Container maxWidth="xl">
                    <Grid container spacing={3}>
                        <Grid xs={12} md={6} order={{ xs: 2, md: 1 }}>
                            <img
                                className="feature-img"
                                src={templatesFeatureGraphic}
                                alt="Shared email templates for teams"
                            />
                        </Grid>
                        <Grid
                            xs={12}
                            md={6}
                            order={{ xs: 1, md: 2 }}
                            className="text-content"
                        >
                            <Typography className="section-title" variant="h2">
                                One team. One voice.
                            </Typography>
                            <Typography variant="h5">
                                Create a shared library of email templates for
                                your teams. Ensure that every message sent by
                                your teams are on-brand, consistent, and always
                                up-to-date.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="section feature darkest full-width">
                <Container maxWidth="xl">
                    <Grid container spacing={3}>
                        <Grid xs={12} md={6} className="text-content">
                            <Typography className="section-title" variant="h2">
                                Sign off with class
                            </Typography>
                            <Typography variant="h5">
                                Create and manage team-wide email signatures
                                with ease. Ensure that every email sent by your
                                team members are signed off professionally and
                                consistently.
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <img
                                className="feature-img"
                                src={signaturesFeatureGraphic}
                                alt="Shared email signatures for teams"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="section feature darkest full-width">
                <Container maxWidth="xl">
                    <Grid container spacing={3}>
                        <Grid xs={12} md={6} order={{ xs: 2, md: 1 }}>
                            <img
                                className="feature-img"
                                src={oneClickToggle}
                                alt="Workflows enabled in a single click"
                            />
                        </Grid>
                        <Grid
                            xs={12}
                            md={6}
                            order={{ xs: 1, md: 2 }}
                            className="text-content"
                        >
                            <Typography className="section-title" variant="h2">
                                Compose in a click
                            </Typography>
                            <Typography variant="h5" className="mb-30">
                                Combine any number of actions to create
                                time-saving workflows that compose every aspect
                                of your outbound email in a click.
                            </Typography>
                            <Typography variant="h5">
                                Share workflows with your team to ensure that
                                every email sent is consistent and professional,
                                while spending less time on repetitive tasks and
                                more time on what matters.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            {/* <div className="section full-width stats">
                <Container maxWidth="xl">
                    <Grid container>
                        <Grid xs={12} md={6} className="stat-container">
                            <Typography variant="h2">
                                Nearly 3X More Efficient
                            </Typography>
                            <Typography variant="h5">
                                Supercharge your team's productivity by
                                automating repetitive email tasks—get emails out
                                nearly 3 times faster with Toggles.
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={6} className="stat-container">
                            <Typography variant="h2">
                                Save Hours Every Week
                            </Typography>
                            <Typography variant="h5">
                                Free up valuable time — cut down hours spent on
                                repetitive tasks each week, allowing your team
                                to focus on what matters most.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div> */}
            <div className="section full-width feature benefits2 darkest">
                <Container maxWidth="xl">
                    <Grid container spacing={3} className="container">
                        <Grid xs={12} lg={6} className="text-content">
                            <Typography variant="h3" className="primary-title">
                                Communicate faster with less effort.
                            </Typography>
                            <Typography variant="body1" className="subtitle">
                                Toggles Business Pro helps organizations
                                streamline email communication and reduce email
                                overload.
                            </Typography>
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <img
                                className="feature-img"
                                src={benefitsGraphic}
                                alt="Toggles benefits graphic - template toggle"
                            />
                        </Grid>
                        <Grid xs={12} lg={4} className="benefit">
                            <Typography variant="h4" className="title">
                                Accomplish more — faster
                            </Typography>
                            <Typography variant="body1" className="description">
                                Send personalized emails with a couple clicks to
                                maximize your team's time.
                            </Typography>
                        </Grid>
                        <Grid xs={12} lg={4} className="benefit">
                            <Typography variant="h4" className="title">
                                Deliver consistent messages
                            </Typography>
                            <Typography variant="body1" className="description">
                                Coordinate templates, attachments and workflows
                                across your team to streamline emails.
                            </Typography>
                        </Grid>
                        <Grid xs={12} lg={4} className="benefit">
                            <Typography variant="h4" className="title">
                                Automate with ease
                            </Typography>
                            <Typography variant="body1" className="description">
                                Create standardized emails and workflows quickly
                                without being a tech whiz.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="section full-width use-cases">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    <span className="animated-color">Unlock productivity</span>{" "}
                    for those who want more.
                </Typography>
                <Container maxWidth="xl">
                    <Grid
                        container
                        spacing={6}
                        className="container"
                        alignItems="stretch"
                    >
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: "40px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <MonetizationOnOutlinedIcon
                                        sx={{ fontSize: "4.5rem" }}
                                    />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Sales
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Automate follow-ups, proposals, and
                                        client outreach to boost productivity,
                                        close deals faster, and focus on
                                        building relationships.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: "40px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <SupportAgentOutlinedIcon
                                        sx={{ fontSize: "4.5rem" }}
                                    />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Customer Support
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Respond to repetitive inquiries, send
                                        case updates, and improve response times
                                        by automating routine emails and
                                        communications.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: "40px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <Diversity3Icon
                                        sx={{ fontSize: "4.5rem" }}
                                    />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Human Resources
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Streamline employee onboarding,
                                        interview scheduling, and policy updates
                                        with automated emails, saving time and
                                        reducing manual tasks.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: "40px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <CampaignIcon sx={{ fontSize: "4.5rem" }} />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Marketing
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Automate email campaigns, follow-ups,
                                        and newsletters to streamline your
                                        communication strategy and free up time
                                        for creative tasks.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: "40px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <GavelIcon sx={{ fontSize: "4.5rem" }} />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Legal
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Reduce time spent on case updates,
                                        contract renewals, and compliance
                                        reminders by automating routine legal
                                        communication.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: "40px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <Groups3Icon sx={{ fontSize: "4.5rem" }} />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Management
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Automate project updates, task
                                        reminders, and meeting invitations,
                                        helping you manage teams and projects
                                        with increased efficiency.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: "40px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <ManageAccountsIcon
                                        sx={{ fontSize: "4.5rem" }}
                                    />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        IT & Tech Support
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Simplify IT notifications, system
                                        updates, and support communications by
                                        automating repetitive emails, allowing
                                        more focus on complex tasks.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: "40px" }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <LightbulbOutlinedIcon
                                        sx={{ fontSize: "4.5rem" }}
                                    />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Consulting
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Automate client check-ins, status
                                        updates, and proposal deliveries to
                                        maintain consistent communication and
                                        improve client satisfaction.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="section full-width roi-calculator">
                <ROICalculator />
            </div>

            <div className="section cta">
                <Container maxWidth="lg">
                    <Grid container spacing={3} className="container">
                        <Grid xs={12} md={7}>
                            <Typography variant="h3" className="cta-title">
                                Ready to revolutionize your team's email
                                workflow?
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={5} className="cta-button-container">
                            <Button
                                className="cta-button"
                                variant="outlined"
                                size="large"
                                href="https://hub.togglesforemail.com/signup?type=business"
                            >
                                Start Free Trial{" "}
                                <KeyboardArrowRightIcon className="cta-button-arrow" />
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            {/* <div className="section full-width benefits blue-dark">
                <Container maxWidth="xl">
                    <Grid container>
                        <Grid xs={12} md={5} className="text-content">
                            <Typography variant="h2">
                                Less time crafting emails.
                            </Typography>
                            <Typography variant="h2" className="white">
                                More time focused on important work.
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={7} className="cards">
                            <div className="benefit">
                                <Typography variant="h3">
                                    Save employees hours per week
                                </Typography>
                                <Typography variant="h5">
                                    Eliminate the repetitive steps of sending
                                    emails with a single toggle of a button.
                                </Typography>
                            </div>
                            <div className="benefit">
                                <Typography variant="h3">
                                    Share workflows, templates, and signatures
                                </Typography>
                                <Typography variant="h5">
                                    Ensure everyone has access to the workflows
                                    and content they need across all their
                                    devices.
                                </Typography>
                            </div>
                            <div className="benefit">
                                <Typography variant="h3">
                                    No-code tool that puts you in control
                                </Typography>
                                <Typography variant="h5">
                                    Create workflows that allow for composing
                                    new emails in a matter of seconds – with no
                                    code at all.
                                </Typography>
                            </div>
                            <div className="benefit">
                                <Typography variant="h3">
                                    Never lose the ability to personalize emails
                                </Typography>
                                <Typography variant="h5">
                                    Use variables in the subject line, email
                                    body, and signatures to ensure all your
                                    communications never feel scripted.
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div> */}
            {/* <div className="section feature darker full-width">
                <Container maxWidth="xl">
                    <Grid container>
                        <Grid xs={12} md={6} className="text-content">
                            <Typography className="section-title" variant="h2">Manage with ease</Typography>
                            <Typography variant="h5">
                                Our simple and intuitive dashboard allows you to manage users and groups, 
                                create and share templates, and track usage across your organization.
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <img
                                className="feature-img"
                                src={templatesFeatureGraphic}
                                alt="Shared email templates for teams"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div> */}

            {/* <div className="bp-cta full-width">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    Ready to get started?
                </Typography>
                <Typography
                    className="section-description"
                    variant="h5"
                    align="center"
                >
                    Toggles helps teams of all sizes work smarter and faster by
                    streamlining email communication and reducing email
                    overflow.
                </Typography>
                <Button
                    className="cta-button"
                    variant="outlined"
                    size="large"
                    href="https://hub.togglesforemail.com/signup?type=business"
                >
                    Start 14 day free trial{" "}
                    <KeyboardArrowRightIcon className="cta-button-arrow" />
                </Button>
            </div> */}
        </Container>
    );
}
