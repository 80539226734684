import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";

import howItWorksGraphic from "../assets/how-it-works.gif";

import "./RequestDemo.scss";

emailjs.init({
    publicKey: "IffLaJ4fHC1xDg_-x",
    blockHeadless: true,
});

function Metadata() {
    return (
        <Helmet>
            <title>
                Toggles | Request a demo of our email productivity tool
            </title>
            <meta
                name="description"
                content="See how to streamline workflows, automate team-wide email tasks, and enhance productivity across your business with our Business Pro plan."
            />
            <meta
                itemProp="name"
                content="Toggles | Request a demo of our email productivity tool"
            />
            <meta
                itemProp="description"
                content="See how to streamline workflows, automate team-wide email tasks, and enhance productivity across your business with our Business Pro plan."
            />
            <meta
                itemProp="image"
                content="http://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta property="og:url" content="https://www.gettoggles.com" />
            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content="Toggles | Request a demo of our email productivity tool"
            />
            <meta
                property="og:description"
                content="See how to streamline workflows, automate team-wide email tasks, and enhance productivity across your business with our Business Pro plan."
            />
            <meta
                property="og:image"
                content="https://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta name="twitter:site" content="@togglesforemail" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content="Toggles | Request a demo of our email productivity tool"
            />
            <meta
                name="twitter:description"
                content="See how to streamline workflows, automate team-wide email tasks, and enhance productivity across your business with our Business Pro plan."
            />
            <meta
                name="twitter:image"
                content="https://www.gettoggles.com/graphics/meta-twitter-image-1600x900.jpg"
            />
            <link rel="canonical" href="https://www.gettoggles.com/" />
        </Helmet>
    );
}

export default function RequestDemo() {
    const [formData, setFormData] = useState({
        name: "",
        company: "",
        email: "",
        phone: "",
        website: "",
        numEmployees: "6-10",
        useCase: "",
    });
    const [inputStateChanged, setInputStateChanged] = useState({
        name: false,
        company: false,
        email: false,
        phone: false,
        website: false,
        numEmployees: false,
        useCase: false,
    });
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleFormSubmit = async () => {
        console.log("Form submitted with data: ", formData);
        setLoading(true);
        
        try {
            if (!isDataValid()) {
                throw new Error("Form data is not valid.");
            }

            await sendRequest();
            setLoading(false);
            setEmailSent(true);
            setHasError(false);
        } catch (err) {
            setLoading(false);
            setHasError(true);
            setErrorMessage('An error occurred submitting the form. Please try again later.');
        }
    };

    const sendRequest = () => {
        return emailjs.send(
            "sales-gettoggles-gmail",
            "template_rp35qw7",
            formData
        );
    };

    const isDataValid = () => {
        return (
            formData.name &&
            formData.company &&
            /^\S+@\S+\.\S+$/.test(formData.email)
        );
    };

    return (
        <Container maxWidth="xl" className="request-demo-container">
            <Metadata />

            <Grid
                container
                spacing={6}
                className="section container"
                alignItems="stretch"
            >
                <Grid xs={12} lg={6} className="request-info-container">
                    <div className="request-info">
                        <Typography
                            className="header"
                            variant="h2"
                            component="h1"
                        >
                            See how Toggles can help your business...
                        </Typography>
                        <Typography
                            className="header-details"
                            variant="body1"
                            component="p"
                        >
                            ...and why Toggles is the best choice for your team.
                            Request a demo and we'll show you exactly what it
                            can do for your team (hint: you're going to be
                            pretty impressed).
                        </Typography>
                        <Typography
                            className="request-label"
                            variant="h4"
                            component="h4"
                        >
                            What can I expect?
                        </Typography>
                        <Typography
                            className="request-details"
                            variant="body1"
                            component="p"
                        >
                            A personalized demo with one of our product experts.
                            We'll show you how Toggles can help you streamline
                            workflows, automate team-wide email tasks, and
                            enhance productivity across your business.
                        </Typography>
                        <Typography
                            className="request-label"
                            variant="h4"
                            component="h4"
                        >
                            How long will it take?
                        </Typography>
                        <Typography
                            className="request-details"
                            variant="body1"
                            component="p"
                        >
                            We'll keep it short and sweet. Our demos usually
                            last about 30 minutes.
                        </Typography>
                        <Typography
                            className="request-label"
                            variant="h4"
                            component="h4"
                        >
                            What happens next?
                        </Typography>
                        <Typography
                            className="request-details"
                            variant="body1"
                            component="p"
                        >
                            We'll reach out to schedule a time that works for
                            you. You'll receive a calendar invite with a link to
                            join the demo.
                        </Typography>
                        <Typography
                            className="request-label"
                            variant="h4"
                            component="h4"
                        >
                            Can I get started on my own?
                        </Typography>
                        <Typography
                            className="request-details"
                            variant="body1"
                            component="p"
                        >
                            Absolutely! You can sign up for a{" "}
                            <Link
                                href="https://hub.togglesforemail.com/signup?type=business"
                                underline="none"
                            >
                                free trial
                            </Link>{" "}
                            (no credit card required) and start exploring
                            Toggles on your own. But we think you'll love our
                            demo.
                        </Typography>
                    </div>
                </Grid>
                <Grid xs={12} lg={6} className="request-form-container">
                    {emailSent ? (
                        <div className="request-form">
                            <Typography
                                variant="h4"
                                component="h3"
                                className="success-header"
                            >
                                Thank you for requesting a demo of Toggles!
                            </Typography>
                            <Typography
                                variant="body1"
                                component="p"
                                className="success-details"
                            >
                                We've received your request and will be in touch
                                soon to schedule a time that works for you. If
                                you have any questions in the meantime, feel
                                free to reach out to us at{" "}
                                <Link
                                    href="mailto:sales@gettoggles.com"
                                    underline="none"
                                >
                                    sales@gettoggles.com
                                </Link>
                                .
                            </Typography>
                        </div>
                    ) : (
                        <div className="request-form">
                            <TextField
                                label="Full Name"
                                fullWidth
                                required
                                variant="outlined"
                                className="form-field"
                                disabled={loading}
                                value={formData.name}
                                onChange={(ev) => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        name: ev.target.value,
                                    }));
                                }}
                                error={
                                    inputStateChanged.name && !formData?.name
                                }
                                helperText={
                                    inputStateChanged.name && !formData?.name
                                        ? "Full name is required"
                                        : ""
                                }
                                onBlur={() =>
                                    setInputStateChanged((prev) => ({
                                        ...prev,
                                        name: true,
                                    }))
                                }
                            />
                            <TextField
                                label="Company"
                                fullWidth
                                required
                                variant="outlined"
                                className="form-field"
                                disabled={loading}
                                value={formData.company}
                                onChange={(ev) => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        company: ev.target.value,
                                    }));
                                }}
                                error={
                                    inputStateChanged.company &&
                                    !formData?.company
                                }
                                helperText={
                                    inputStateChanged.company &&
                                    !formData?.company
                                        ? "Company name is required"
                                        : ""
                                }
                                onBlur={() =>
                                    setInputStateChanged((prev) => ({
                                        ...prev,
                                        company: true,
                                    }))
                                }
                            />
                            <TextField
                                label="Email"
                                fullWidth
                                required
                                variant="outlined"
                                className="form-field"
                                disabled={loading}
                                value={formData.email}
                                onChange={(ev) => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        email: ev.target.value,
                                    }));
                                }}
                                error={
                                    inputStateChanged.email &&
                                    !/^\S+@\S+\.\S+$/.test(formData.email)
                                }
                                helperText={
                                    inputStateChanged.email &&
                                    !/^\S+@\S+\.\S+$/.test(formData.email)
                                        ? "Valid email address is required"
                                        : ""
                                }
                                onBlur={() =>
                                    setInputStateChanged((prev) => ({
                                        ...prev,
                                        email: true,
                                    }))
                                }
                            />
                            <TextField
                                label="Phone"
                                fullWidth
                                variant="outlined"
                                className="form-field"
                                disabled={loading}
                                value={formData.phone}
                                onChange={(ev) => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        phone: ev.target.value,
                                    }));
                                }}
                            />
                            <TextField
                                label="Website"
                                fullWidth
                                variant="outlined"
                                className="form-field"
                                disabled={loading}
                                value={formData.website}
                                onChange={(ev) => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        website: ev.target.value,
                                    }));
                                }}
                            />
                            <TextField
                                label="Number of Employees"
                                fullWidth
                                required
                                select
                                SelectProps={{ native: true }}
                                variant="outlined"
                                className="form-field"
                                disabled={loading}
                                value={formData.numEmployees}
                                onChange={(ev) => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        numEmployees: ev.target.value,
                                    }));
                                }}
                            >
                                {[
                                    "1",
                                    "2-5",
                                    "6-10",
                                    "11-25",
                                    "26-50",
                                    "51-100",
                                    "101-250",
                                    "251-500",
                                    "501-1000",
                                    "1000+",
                                ].map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </TextField>
                            <TextField
                                label="Any specific use cases you'd like to see?"
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                className="form-field"
                                disabled={loading}
                                value={formData.useCase}
                                onChange={(ev) => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        useCase: ev.target.value,
                                    }));
                                }}
                            />
                            <Typography
                                variant="body2"
                                component="p"
                                className="form-disclaimer"
                            >
                                We're committed to your privacy. Toggles uses
                                the information you provide to us to contact you
                                about our relevant content, products, and
                                services. You may unsubscribe from these
                                communications at any time. For more
                                information, check out our{" "}
                                <Link href="/privacy" underline="none">
                                    Privacy Policy
                                </Link>
                                .
                            </Typography>
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                size="large"
                                onClick={handleFormSubmit}
                                disabled={emailSent || !isDataValid()}
                                loading={loading}
                            >
                                Request a free demo
                            </LoadingButton>

                            {hasError && (
                                <Typography
                                    variant="body2"
                                    component="p"
                                    className="error-message"
                                >
                                    {errorMessage}
                                </Typography>
                            )}
                        </div>
                    )}
                </Grid>
            </Grid>
            <div className="section full-width feature-overview">
                <Container maxWidth="xl">
                    <Grid container spacing={3}>
                        <Grid xs={12} lg={4} className="text-content">
                            <Typography className="section-title" variant="h2">
                                Dont just take our word for it
                            </Typography>
                            <Typography className="section-description" variant="h5">
                                See for yourself how Toggles for Outlook automation software can meet 
                                your business's unique challenges, needs, and goals. Complete the form above 
                                to request your free demo.
                            </Typography>
                        </Grid>
                        <Grid xs={12} lg={8} className="graphic">
                            <img
                                src={howItWorksGraphic}
                                alt="How Toggles for Outlook works"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Container>
    );
}
