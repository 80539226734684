// @ts-nocheck
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import Tooltip from "@mui/material/Tooltip";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import ArticleIcon from "@mui/icons-material/Article";
import DrawIcon from "@mui/icons-material/Draw";
import SnoozeIcon from "@mui/icons-material/Snooze";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import ReactGA from "react-ga4";

const ACTION_TYPES = {
    Tag: "tag",
    Recipient: "recipient",
    Attachment: "attachment",
    Link: "link",
    Template: "template",
    Signature: "signature",
    Delay: "delay",
};

import "./styles.scss";

const RuleToggle = ({ rule, isEnabled, onChange }) => {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(false);

    useState(() => {
        setIsExpanded(false);
    }, [rule]);

    const renderAction = (action, ruleId, idx) => {
        switch (action.type) {
            case ACTION_TYPES.Tag:
                return (
                    <div className="action" key={`${ruleId}-action-${idx}`}>
                        <LocalOfferIcon
                            className="action-icon"
                            sx={{ fontSize: 16 }}
                        />
                        <span className="action-details">
                            Add {action.tag} to subject
                        </span>
                    </div>
                );
            case ACTION_TYPES.Recipient:
                return action.emails.map((email, emailIdx) => (
                    <div
                        className="action"
                        key={`${ruleId}-action-email-${emailIdx}`}
                    >
                        <PersonAddAlt1Icon
                            className="action-icon"
                            sx={{ fontSize: 16 }}
                        />
                        <span className="action-details">
                            Add recipient {action.recipientType}:{email}
                        </span>
                    </div>
                ));
            case ACTION_TYPES.Attachment:
                return action.attachments.map((att, attIdx) => (
                    <div
                        className="action"
                        key={`${ruleId}-action-attachment-${attIdx}`}
                    >
                        <AttachFileIcon
                            className="action-icon"
                            sx={{ fontSize: 16 }}
                        />
                        <span className="action-details">
                            Add attachment {att.name}
                        </span>
                    </div>
                ));
            case ACTION_TYPES.Link:
                return (
                    <div className="action" key={`${ruleId}-action-${idx}`}>
                        <InsertLinkIcon
                            className="action-icon"
                            sx={{
                                fontSize: 16,
                            }}
                        />
                        <span className="action-details">
                            Add{" "}
                            <a
                                className="action-details-link"
                                href={action.url}
                                title={action.text}
                                target="_blank"
                                style={{ color: theme.palette.primary.main }}
                            >
                                {action.text}
                            </a>{" "}
                            link
                        </span>
                    </div>
                );
            case ACTION_TYPES.Template:
                return (
                    <div className="action" key={`${ruleId}-action-${idx}`}>
                        <ArticleIcon
                            className="action-icon"
                            sx={{
                                fontSize: 16,
                            }}
                        />
                        <span className="action-details">
                            Insert "{action.template.name}" template
                        </span>
                    </div>
                );
            case ACTION_TYPES.Signature:
                return (
                    <div className="action" key={`${ruleId}-action-${idx}`}>
                        <DrawIcon
                            className="action-icon"
                            sx={{
                                fontSize: 16,
                            }}
                        />
                        <span className="action-details">
                            Use "{action.signature.name}" signature
                        </span>
                    </div>
                );
            case ACTION_TYPES.Delay:
                return (
                    <div className="action" key={`${ruleId}-action-${idx}`}>
                        <SnoozeIcon
                            className="action-icon"
                            sx={{
                                fontSize: 16,
                            }}
                        />
                        <span className="action-details">
                            Schedule send on toggle
                        </span>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Paper
            className={`rule-container ${isEnabled ? "enabled" : ""} ${
                isExpanded ? "expanded" : ""
            }`}
            elevation={3}
        >
            <div className="rule">
                {isExpanded ? (
                    <Tooltip
                        placement="top-start"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title={`${rule.description} (click for ${
                            isExpanded ? "less" : "more"
                        } details)`}
                        arrow
                    >
                        <ExpandLessOutlinedIcon
                            className="show-more-icon"
                            onClick={() => setIsExpanded(!isExpanded)}
                        />
                    </Tooltip>
                ) : (
                    <Tooltip
                        placement="top-start"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title={`${rule.description} (click for ${
                            isExpanded ? "less" : "more"
                        } details)`}
                        arrow
                    >
                        <ExpandMoreOutlinedIcon
                            className="show-more-icon"
                            onClick={() => setIsExpanded(!isExpanded)}
                        />
                    </Tooltip>
                )}

                <div className="details">{rule.name}</div>
                <div className="toggle-button">
                    <Switch
                        checked={isEnabled}
                        onChange={(event) => {
                            onChange(rule, event.target.checked)

                            if (event.target.checked) {
                                ReactGA.event({
                                    category: "Toggle Rule Click",
                                    action: `${rule.name} enabled`,
                                    label: "Demo",
                                });
                            }
                        }}
                    />
                </div>
            </div>
            {isExpanded && (
                <>
                    <Divider>
                        <Chip label="Actions" size="small" />
                    </Divider>
                    <div className="rule-details">
                        <div className="actions">
                            {rule.metadata.actions.map((action, idx) => {
                                return renderAction(action, rule.id, idx);
                            })}
                        </div>
                    </div>
                </>
            )}
        </Paper>
    );
};

export default RuleToggle;
