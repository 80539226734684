// @ts-nocheck
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import StandardPriceCard from "../components/Pricing/StandardPriceCard";
import PremiumPriceCard from "../components/Pricing/PremiumPriceCard";
import BusinessProPriceCard from "../components/Pricing/BusinessProPriceCard";
import ComparisonTable from "../components/Pricing/ComparisonTable";

import outlookIcon from "../assets/outlook-icon.png";
import "./Pricing.scss";

function Metadata() {
    return (
        <Helmet>
            <title>Toggles Pricing | Premium Email Productivity Tools</title>
            <meta
                name="description"
                content="Discover the pricing options for Toggles. Choose from premium features including unlimited rules, advanced logic, and premium action types. Find the perfect plan for your organization."
            />
            <meta property="og:url" content="https://www.gettoggles.com" />
            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content="Toggles Pricing | Premium Email Productivity Tools"
            />
            <meta
                property="og:description"
                content="Discover the pricing options for Toggles. Choose from premium features including unlimited rules, advanced logic, and premium action types. Find the perfect plan for your organization."
            />
            <meta
                property="og:image"
                content="https://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta name="twitter:site" content="@togglesforemail" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content="Toggles Pricing | Premium Email Productivity Tools"
            />
            <meta
                name="twitter:description"
                content="Discover the pricing options for Toggles. Choose from premium features including unlimited rules, advanced logic, and premium action types. Find the perfect plan for your organization."
            />
            <meta
                name="twitter:image"
                content="https://www.gettoggles.com/graphics/meta-twitter-image-1600x900.jpg"
            />
            <link rel="canonical" href="https://www.gettoggles.com/pricing" />
        </Helmet>
    );
}

export default function Pricing() {
    const [interval, setInterval] = useState("annual");

    const handleIntervalChange = (event, newInterval) => {
        setInterval(newInterval);
    };

    return (
        <Container maxWidth="xl" className="pricing-container">
            <Metadata />

            <Typography className="section-title" variant="h1" align="center">
                Ready to Accomplish
                <br />
                More — Faster?
            </Typography>
            <Typography
                className="section-description"
                variant="h4"
                align="center"
            >
                Try <span className="animated-color">Toggles</span> For Free And
                Find Your Perfect Plan
            </Typography>

            <div className="pricing-interval">
                <ToggleButtonGroup
                    value={interval}
                    exclusive
                    onChange={handleIntervalChange}
                    aria-label="Payment Interval"
                >
                    <ToggleButton
                        className={`interval-selection ${
                            interval === "month" ? "selected" : ""
                        }`}
                        value="month"
                        aria-label="monthly"
                    >
                        Monthly Billing
                    </ToggleButton>
                    <ToggleButton
                        className={`interval-selection ${
                            interval === "annual" ? "selected" : ""
                        }`}
                        value="annual"
                        aria-label="annually"
                    >
                        Yearly Billing (Save 20%)
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>

            <Container maxWidth="xl" className="price-cards-container">
                <Grid container spacing={2}>
                    <Grid xs={12} md={4}>
                        <StandardPriceCard showButton={false} />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <PremiumPriceCard
                            showButton={false}
                            interval={interval}
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <BusinessProPriceCard
                            showButton={false}
                            interval={interval}
                        />
                    </Grid>
                </Grid>
            </Container>

            <Container
                maxWidth="md"
                className="comparison-table-container"
                sx={{ display: { xs: "none", md: "block" } }}
            >
                <ComparisonTable />
            </Container>

            <Container maxWidth="xl" className="home-cta section">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    Unlock email efficiency
                </Typography>
                <Typography
                    className="section-description"
                    variant="h5"
                    align="center"
                >
                    Spend less time coordinating emails and more time focused on
                    what really matters.
                </Typography>
                <Button
                    className="cta-button"
                    variant="outlined"
                    size="large"
                    href="https://hub.togglesforemail.com/signup?type=premium"
                >
                    Get started today{" "}
                    <KeyboardArrowRightIcon className="cta-button-arrow" />
                </Button>
            </Container>
        </Container>
    );
}
