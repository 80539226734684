import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

import './styles.scss';

export default function Copyright() {
    return (
        <Typography
            className="copyright"
            variant="body2"
            color="text.secondary"
            align="center"
        >
            {"Copyright © "}
            <Link color="inherit" component={RouterLink} to="/">
                Toggles
            </Link>{" "}
            {new Date().getFullYear()} |{" "}
            <Link
                component={RouterLink}
                to="/terms"
                underline="none"
                color="inherit"
            >
                Terms of Service
            </Link>{" "}
            |{" "}
            <Link
                component={RouterLink}
                to="/privacy"
                underline="none"
                color="inherit"
            >
                Privacy Policy
            </Link>
        </Typography>
    );
}
