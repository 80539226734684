import * as React from "react";
import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import "./Terms.scss";

function Metadata() {
    return (
        <Helmet>
            <title>Toggles Terms of Service | Email Productivity Add-On</title>
            <meta
                name="description"
                content="Review the Terms of Service for Toggles. Understand our policies, usage terms, and guidelines for using our email productivity add-on effectively and responsibly."
            />
            <meta property="og:url" content="https://www.gettoggles.com" />
            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content="Toggles Terms of Service | Email Productivity Add-On"
            />
            <meta
                property="og:description"
                content="Review the Terms of Service for Toggles. Understand our policies, usage terms, and guidelines for using our email productivity add-on effectively and responsibly."
            />
            <meta
                property="og:image"
                content="https://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta name="twitter:site" content="@togglesforemail" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content="Toggles Terms of Service | Email Productivity Add-On"
            />
            <meta
                name="twitter:description"
                content="Review the Terms of Service for Toggles. Understand our policies, usage terms, and guidelines for using our email productivity add-on effectively and responsibly."
            />
            <meta
                name="twitter:image"
                content="https://www.gettoggles.com/graphics/meta-twitter-image-1600x900.jpg"
            />
            <link rel="canonical" href="https://www.gettoggles.com/terms" />
        </Helmet>
    );
}

export default function Privacy() {
    return (
        <Container maxWidth="lg" className="terms-container">
            <Metadata />

            <Typography className="section-title" variant="h2" align="center">
                Terms of Service
            </Typography>
            <Typography variant="h6" align="center">
                Terms and conditions applicable to the use of the Toggles
                services and applications.
            </Typography>

            <div className="policy">
                <Typography variant="body1" gutterBottom>
                    These Terms of Use ("Terms") apply to all users of the
                    Toggles Services ("Services") and Applications
                    ("Applications").
                </Typography>
                <Typography variant="body1" gutterBottom>
                    These Terms govern your use of the Services and Applications provided to you
                    by Toggles. ("Toggles or "Company" as used in these Terms)
                    at the website located at https://www.gettoggles.com and any
                    subdomains (the "Site"). By using the Services and Applications, you are
                    stating that you have read, understood, and agreed to be
                    bound by these Terms. If you do not agree to these Terms,
                    you are not permitted to use the Toggles Services and Applications.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    As used in these Terms, "you" and "Customer" refers to you
                    as our customer personally, and/or the company or other
                    entity who is our customer, who is or will be using our
                    services and applications. The terms "we" and "our" refer to Toggles.
                </Typography>

                <Typography variant="h4" className="mb-40 mt-80">
                    1. Who May Use the Services and Applications
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Except for our free version of the Toggles Services and
                    Applications, your right to use the Services and
                    Applications depends on you timely paying the required fees.
                    You agree to use the Services and Applications only as
                    permitted in these Terms.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You accept and assume sole responsibility for your use of
                    the Services and Applications.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You may not share your login information or credentials with
                    any other person or entity.
                </Typography>

                <Typography variant="h4" className="mb-40 mt-80">
                    2. Limitations on Use of the Services and Applications
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You agree that you will at all times use the Services and
                    Applications consistent with all applicable laws and
                    regulations, not violating any law, legal right or
                    protection, regulation, legal prohibition, privacy right, or
                    intellectual property right.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    THIS INCLUDES BUT IS NOT LIMITED TO LAWS AND REGULATIONS
                    PERTAINING TO EMAIL SOLICITATION, SPAM, FINANCIAL AND
                    MONETARY SCHEMES, PHISHING, COMPUTER FRAUD, COMPUTER CRIMES,
                    UNAUTHORIZED COLLECTION OR USE OF DATA OF ANY KIND INCLUDING
                    BUT NOT LIMITED TO DATA THAT IS PROTECTED BY LAW, FRAUD,
                    DECEPTION, INVASION OF PRIVACY, DEFAMATION, DISCRIMINATION,
                    HARASSMENT, TERRORISM, AND THE LIKE.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    YOU MAY NOT USE THE TOGGLES TO COLLECT, USE, OR DISCLOSE
                    PERSONAL INFORMATION PROTECTED FROM ANY OF THESE ACTIVITIES
                    UNDER PRIVACY LAWS. YOU FURTHER AGREE TO NOT USE THE SERVICE
                    TO COLLECT, USE, OR DISCLOSE CREDIT CARD INFORMATION OR ANY
                    TYPE OF LOGIN CREDENTIALS. YOU ARE SOLELY RESPONSIBLE FOR
                    COMPLIANCE WITH ANY DATA PROTECTION AND PRIVACY LAWS AND
                    RULES APPLICABLE TO THE SENSITIVE INFORMATION.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    YOU AGREE THAT TOGGLES MAY TEMPORARILY OR PERMANENTLY
                    DISABLE YOUR ACCESS TO THE SERVICES AND APPLICATIONS IF WE
                    HAVE A GOOD FAITH BELIEF THAT YOU HAVE USED THE SERVICES OR
                    APPLICATIONS IN VIOLATION OF LAW OR OF ANY PERSON’S LEGAL
                    RIGHTS.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You and agents hereby forever release Toggles from any and
                    all responsibility for any and all wrongs and violations
                    committed by you relating to your use of the Services or
                    Applications.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You agree that we have the right to immediately suspend your
                    use of the Services and Applications without prior notice
                    and without any obligation or refund of fees you’ve paid us
                    if you violate any provision of this Section 2.
                </Typography>

                <Typography variant="h4" className="mb-40 mt-80">
                    3. Your Account Terms
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You may not access the Toggles Services or Applications
                    through automated methods, such as the use of robots or
                    other computer code which calls the Services or Applications, except where
                    we’ve given you express written permission.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Each user must provide a valid email address and any other
                    information requested in order to fully complete the signup
                    process and create a login. You may only create a separate
                    login for as many Users as your current Toggles Plan allows.
                    The sharing of your Toggles Account login credentials is
                    strictly prohibited. You are responsible for maintaining the
                    security of each User’s account, username and password and
                    for ensuring that each User associated with your Toggles
                    Account complies with these Terms.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Toggles is not liable for any loss or damage from your (or
                    your Users’) failure to comply with these Terms. You are
                    solely responsible for all Data posted to your account,
                    whether or not you personally posted the Data.
                </Typography>

                <Typography variant="h4" className="mb-40 mt-80">
                    4. Payments, Renewals, Credit Card Details, Downgrades,
                    Refunds
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Payments.</strong> All fees are exclusive of all
                    taxes, levies, or duties imposed by taxing authorities,
                    except for sales taxes as we may be required to add under
                    local law. You shall be responsible for payment of all such
                    taxes, levies, and duties, including any sale or value-added
                    taxes and similar taxes and duties imposed by any governing
                    authority in any jurisdiction in connection with your use of
                    the Toggles Services and Applications.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Toggles offers payments through third-party payment
                    processors. By making payments to us for Toggles services
                    and Applications, you indicate that you have reviewed the
                    Terms of Services and privacy policy of the payment
                    processor for the country in which you are located and agree
                    to both.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Automatic Renewal.</strong> Paid accounts are
                    subscriptions. This means that you will be billed in advance
                    on a recurring, periodic basis. Your Toggles subscription
                    will automatically renew at the end of each billing cycle
                    until you cancel your Toggles subscription by selecting to
                    cancel your subscription on your Account page. You can also
                    cancel your Toggles subscription by submitting a request to
                    the Toggles Support Team. If you downgrade, your account
                    will remain at your current plan through the end of your
                    current billing cycle.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Credit card details.</strong> If you have elected to
                    pay the fees for a Toggles subscription by credit card, you
                    warrant that the credit card information you provide is
                    correct and that you will promptly notify us of any changes
                    to such credit card information. You agree that if your
                    credit card payment cannot be processed for any reason,
                    Toggles may suspend or cancel your Toggles subscription.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Downgrades</strong> Downgrading your Toggles account
                    plan may cause the loss of features or capacity of your
                    account. To the extent permitted by applicable law, Toggles
                    does not accept any liability for such losses. Downgrading
                    your subscription will not cause you to lose your data.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Cancellation</strong> Paid plans are subscriptions
                    that auto-renew by default. You can cancel auto-renew at any
                    time to cancel your subscription. To cancel your Toggles
                    subscription at any time, go to the Billing section of your
                    account, and downgrade your account to the free Starter plan
                    or submit a request to the Toggles Support Team. You can
                    delete your account entirely by submitting a request to the
                    Toggles Support Team. Canceling auto-renew prevents your
                    payment method from being charged at your next billing date,
                    or if you pay by invoice, prevents your next invoice from
                    being issued. Your current subscription (the time you
                    already paid for) isn’t affected, so you’ll continue to be
                    able to access and benefit from paid features until the plan
                    downgrades on your next billing date.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Refunds</strong> Toggles does not provide refunds.
                    You may cancel your Toggles subscription at any time, and
                    you will not be charged at your next billing date. You will
                    continue to have access to your paid features until the end
                    of your billing cycle
                </Typography>

                <Typography variant="h4" className="mb-40 mt-80">
                    5. Toggles' Use of Data
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You hereby authorize us to access, use and display Data for
                    the purpose of and to the extent necessary to provide the
                    Services and Applications to you, to protect the Data, and
                    to protect our online or computer resources from unlawful
                    cyberattacks. We will not modify any Data, copy Data onto
                    any media, disassemble, decompile or reverse engineer all or
                    any part of the Data, or use, duplicate, transfer, sell,
                    distribute or otherwise disclose the Data to any other
                    party.
                </Typography>

                <Typography variant="h4" className="mb-40 mt-80">
                    6. Trademarks
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You shall not use or display the Toggles trademark or logo
                    of the other party without our written permission. This
                    section shall survive termination or expiration of this
                    Agreement.
                </Typography>

                <Typography variant="h4" className="mb-40 mt-80">
                    7. Term and Termination
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You shall be permitted to use the Services and Applications
                    for the period of time for which you have paid us the
                    required fees to use the Services and Applications. You
                    agree that we may immediately terminate this Agreement if
                    you materially breach any provision of this Agreement.
                </Typography>

                <Typography variant="h4" className="mb-40 mt-80">
                    8. Warranties
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Mutual Warranty.</strong> Each party warrants to the
                    other party that it has the legal power and authority to
                    enter into this Agreement and that the person signing up for
                    that party to Toggles Services and Applications has the
                    authority to bind that party to the terms of this Agreement.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Disclaimer of Implied Warranties.</strong> THE
                    SERVICES AND APPLICATIONS ARE PROVIDED AS-IS. TOGGLES DISCLAIMS ALL IMPLIED
                    REPRESENTATIONS AND WARRANTIES, INCLUDING, WITHOUT
                    LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS
                    FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF
                    THIRD-PARTY RIGHTS, TO THE MAXIMUM EXTENT PERMITTED BY
                    APPLICABLE LAW.
                </Typography>

                <Typography variant="h4" className="mb-40 mt-80">
                    9. Defense &amp; Indemnity
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You agree that you will defend Toggles against claims or
                    proceedings alleging that Data or our transmission or
                    hosting thereof infringes or violates the rights of a third
                    party or violates data privacy or protection laws, and you
                    agree to indemnify Toggles against damages and costs
                    (including reasonable attorneys’ fees) finally awarded by a
                    court of competent jurisdiction or in a settlement of the
                    claim approved in writing by you.
                </Typography>

                <Typography variant="h4" className="mb-40 mt-80">
                    10. Limitation of Liability
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Limitation on Damages.</strong> EXCEPT FOR YOUR
                    OBLIGATION TO PAY FEES, IN NO EVENT SHALL TOGGLES’S
                    LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT
                    WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF
                    LIABILITY, EXCEED IN THE AGGREGATE THE TOTAL AMOUNT PAID BY
                    YOU FOR THE SERVICES AND APPLICATIONS IN THE TWELVE (12) MONTHS IMMEDIATELY
                    PRECEDING WHEN THE CLAIM AROSE.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Disclaimer of Consequential Damages. </strong>EXCEPT
                    AS MAY BE PROHIBITED BY LAW, IN NO EVENT SHALL EITHER PARTY
                    BE LIABLE FOR ANY LOST PROFITS OR LOST REVENUE OR FOR ANY
                    INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
                    DAMAGES ARISING UNDER THIS AGREEMENT, EVEN IF THE PARTY HAS
                    BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OCCURRING.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Scope of Limitations on Liability. </strong>THE
                    LIMITATIONS SET FORTH IN THIS SECTION 10 SHALL APPLY
                    NOTWITHSTANDING THE FAILURE OF THE ESSENTIAL PURPOSE OF ANY
                    REMEDY AND REGARDLESS OF THE LEGAL OR EQUITABLE THEORY ON
                    WHICH CLAIMS ARE BROUGHT.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>
                        Additional information for California residents.
                    </strong>{" "}
                    With regard to the California Consumer Privacy Act of 2018,
                    Toggles does not sell or share or make available your form
                    data without your express permission. Although we do not
                    sell or share your personal information, if you would like
                    to opt-out from us ever doing so, please submit a written
                    request to our{" "}
                    <a href="https://www.gettoggles.com/support">
                        Customer Support Team
                    </a>
                    .
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Copyright Infringement.</strong> Toggles respects
                    the intellectual property rights of others. Accordingly,
                    Toggles has a policy of disabling access to any data that
                    violates copyright law, suspending access to the Toggles
                    Services and Applications to any user who uses the Toggles
                    Services or Applications in violation of copyright law,
                    and/or terminating in appropriate circumstances the account
                    of any user who uses the Toggles Services and Applications
                    in violation of copyright law.
                </Typography>

                <Typography variant="h4" className="mb-40 mt-80">
                    11. Other Provisions
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Modifications To the Services.</strong> Toggles
                    reserves the right to modify the Services and Applications
                    with or without notice to you. Toggles shall not be liable
                    to you or any third party for any such modifications.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Email Communications.</strong> By giving your email
                    address to Toggles, you agree to receive occasional
                    administrative, announcements, newsletters, sales, and
                    marketing emails from Toggles. You can opt out from these
                    emails by clicking on the "unsubscribe" link at the end of
                    the emails or contacting our customer support team.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>No Resale Of the Services or Applications.</strong> You agree not to
                    reproduce, duplicate, copy, sell, resell, or exploit for any
                    commercial purposes any portion the Services or
                    Applications, use of the Services or Applications, or access
                    to the Services or Applications.
                </Typography>

                <Typography variant="h4" className="mb-40 mt-80">
                    12. General
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Assignment. </strong>You may not assign any of your
                    rights or obligations under this Agreement without the prior
                    written consent of Toggles (not to be unreasonably
                    withheld), except, if you are a company or other entity, in
                    connection with a merger, acquisition, corporate
                    reorganization, or sale of all or substantially all of the
                    entity’s assets. In the case of an assignment permitted
                    under this section, you agree to ensure that the assignee
                    agrees in writing to the terms of this Agreement.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>
                        Relationship of the parties; No Third-Party
                        Beneficiaries.{" "}
                    </strong>
                    The parties hereto are independent entities. Nothing in this
                    Agreement or any attachment hereto creates or will create
                    any partnership, joint venture, agency, franchise, sales
                    representative, or employment relationship between the
                    parties. There are no third-party beneficiaries to this
                    Agreement.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Choice of Law. </strong>This Agreement shall be
                    governed by and construed in accordance with the laws of the
                    State of Wisconsin, excluding its conflicts of law
                    provisions, and of the United States if the issue is federal
                    in nature. The parties agree that the United Nations
                    Convention on Contracts for the International Sale of Goods
                    will not apply to this Agreement.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Disputes; Arbitration. </strong>The parties agree
                    that all disputes between them shall be finally resolved by
                    binding arbitration before a single neutral arbitrator under
                    the auspices of the American Arbitration Association if the
                    arbitration is filed in the United States, or under the
                    auspices of the ICC (International Court of Arbitration) if
                    the arbitration is filed in a country in which ICC has
                    offices; arbitration shall not be filed or take place in any
                    other location. The arbitrator shall give a written opinion
                    stating the factual basis and legal reasoning for their
                    decision. The prevailing party shall be entitled to an award
                    of its reasonable attorneys’ fees and costs associated with
                    the arbitration. An arbitration award shall be enforceable
                    in a court of competent jurisdiction over the parties. No
                    claim shall be initiated or filed against the other party
                    more than one year after the cause of action arises.
                    Notwithstanding the foregoing, any request by a party for
                    injunctive relief shall be brought before a court of
                    competent jurisdiction and not through arbitration, nor
                    shall an arbitrator have the authority to issue injunctive
                    relief. THE PARTIES HEREBY VOLUNTARILY WAIVE A TRIAL BY JURY
                    OF ALL CLAIMS.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Manner of Giving Notice. </strong>Notices regarding
                    this Agreement shall be in writing and addressed to us to
                    support@gettoggles.com.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Toggles shall not be liable to you for any delay or failure
                    to perform hereunder (excluding payment obligations which
                    may be delayed but not excused) due to circumstances beyond
                    such party’s reasonable control, including acts of God, acts
                    of government, pandemic, flood, fire, earthquakes, civil
                    unrest, acts of terror, strikes or other labor problems
                    (excluding those involving such party’s employees), service
                    disruptions involving hardware, software or power systems
                    not within such party’s reasonable control, and denial of
                    service attacks.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Except as prohibited by law, English shall be the governing
                    language of this Agreement.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Entire Agreement. </strong>This Agreement, together
                    with the Attachments hereto, represents the entire agreement
                    of the parties concerning the subject matter thereof and is
                    intended to be the final expression of their parties’
                    agreement and intent. This Agreement supersedes all prior
                    and contemporaneous agreements, proposals, and
                    representations, whether written or oral. The parties agree
                    that any terms or conditions stated or referenced in or on a
                    document or documents other than this Agreement that
                    contradict this Agreement are null and void. No amendment,
                    addendum, or other document the intent of which is to add to
                    or otherwise modify the Agreement, or waiver of any
                    provision of the Agreement, shall be effective unless in
                    writing and signed by both parties.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Severability; Construing; Counterparts.</strong> If
                    any provision of this Agreement is held by a court of
                    competent jurisdiction to be contrary to law, such provision
                    shall be modified by the court and interpreted so as best to
                    accomplish the objectives of the original provision to the
                    fullest extent permitted by law, and the remaining
                    provisions shall remain in effect. The parties expressly
                    agree that this Agreement shall not be construed against
                    either party as the drafter. This Agreement may be executed
                    in counterparts.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Termination. </strong>You agree that Toggles may
                    terminate your Toggles Services and Applications membership
                    or suspend your access to all or part of the Toggles
                    Services and Applications, without notice, if Toggles
                    determines, in its sole and absolute discretion, that you
                    have violated these Terms. Further, you agree that Toggles
                    shall not be liable to you or any third party for removing
                    your submissions or suspending or terminating your access to
                    the Toggles Services and Applications. You may discontinue
                    your participation in and access to the Toggles Services and
                    Applications at any time.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Upon termination of this Agreement, for any reason, you
                    agree to cease all use of the Toggles Services and
                    Applications. Any termination will not affect your
                    obligations to us under this Agreement (including, without
                    limitation, payments, ownership, indemnification and
                    limitation of liability) which are intended to survive such
                    suspension or termination. We will be entitled to
                    discontinue prospective hosting of your data.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Modifications To Terms.</strong> Toggles may, in its
                    sole and absolute discretion, change these Terms from time
                    to time. Toggles will post notice of such changes on the
                    Site. If you object to any such changes, your sole recourse
                    shall be to cease using the Toggles Services and
                    Applications. Continued use of the Toggles Services and
                    Applications following notice of any such changes shall
                    indicate your acknowledgement of such changes and agreement
                    to be bound by the terms and conditions of such changes.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Modifications To Toggles Services and Applications.</strong> Prices
                    of all Toggles Services and Applications, including but not
                    limited to subscription plan fees to the Toggles Services
                    and Applications. Toggles reserves the right to modify or
                    discontinue the Toggles Services and Applications with or
                    without notice to you. Any subscription(s) that are pre-paid
                    shall not be affected by this notice until the expiration of
                    said subscription(s). Such notice may be provided at any
                    time by posting the changes to the Toggles website or the
                    Toggles Services and Applications itself.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Toggles shall not be liable to you or any third party should
                    Toggles exercise its right to modify or discontinue the
                    Toggles Services and Applications.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Toggles shall not be liable to you or to any third party for
                    any modification, price change, suspension or discontinuance
                    of the Services and Applications as envisaged under
                    Termination section.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Toggles shall not be liable in any event for matters which
                    arise due to circumstances beyond our reasonable control.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Links. </strong>Toggles’ provision of a link to any
                    other website or Internet resource is for your convenience
                    only and does not signify Toggles’s endorsement of such
                    other web site or resource or its contents. Toggles shall
                    have no responsibility or liability for any information,
                    software, or materials found at any other web site or
                    Internet resource.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Toggles is not responsible for the content on the Internet
                    or World Wide Web pages that are contained outside the
                    Websites. As a convenience to our members, Toggles provides
                    links to resources. Toggles makes no representations as to
                    the quality, suitability, functionality or legality of any
                    websites to which Toggles may provide links, and you hereby
                    waives any claim you or the User may have against Toggles
                    with respect to any such websites. Unless you have a written
                    agreement or a specific consent in effect with Toggles which
                    states otherwise, links to the Websites may be provided only
                    in the following manner: (a) links must not suggest or
                    otherwise create the false appearance that Toggles is
                    affiliated with any person or entity, or that Toggles
                    otherwise endorses, sponsors or affiliated with any product
                    or service; (b) the appearance, position and other aspects
                    of any link to the Websites may not be such as to damage or
                    dilute the goodwill associated with the Toggles name and
                    Trademarks; (c) all links to the Websites must "point" to
                    the URL "www.gettoggles.com" and not to other pages within
                    the Website; (d) all links to the Website, when activated by
                    a user, must not display the Website within a "frame" on the
                    linking website, or any other website.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Indemnification. </strong>You agree to indemnify,
                    defend, and hold harmless Toggles, its parents,
                    subsidiaries, affiliates, officers, directors, employees,
                    consultants, and agents from and against any and all claims,
                    liabilities, damages, losses, costs, expenses, and fees
                    (including reasonable attorneys. fees) that such parties may
                    incur as a result of or arising from (a) any information
                    (including, without limitation, your Submissions or any
                    other content) you (or anyone using your account) submits,
                    posts, or transmits through the Toggles Services and
                    Applications, (b) your (or anyone using your account’s) use
                    of the Toggles Services and Applications, (c) your (or
                    anyone using your account’s) violation of these Terms, and
                    (d) your (or anyone using your account’s) violation of any
                    rights of any other person or entity.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The failure of Toggles to exercise or enforce any right or
                    provision of these Terms shall not constitute a waiver of
                    such right or provision. If any provision of these Terms is
                    found by a court of competent jurisdiction to be invalid,
                    you nevertheless agree that the court should endeavor to
                    give effect to the intentions of Toggles and you as
                    reflected in the provision, and that the other provisions of
                    these Terms remain in full force and effect. You agree that
                    regardless of any statute or law to the contrary, any claim
                    or cause of action arising out of or related to use of the
                    Toggles Services and Applications or these Terms must be
                    filed within one (1) year after such claim or cause of
                    action arose or be forever barred. The section titles in
                    these Terms are for convenience only and have no legal or
                    contractual effect. All terms, as well as any limitations on
                    liability explicitly set forth in these Terms, shall remain
                    in full force and effect notwithstanding any termination of
                    your use of the Toggles Services and Applications.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Third Party Vendors. </strong>The User may order or
                    use services through the Toggles Services and Applications
                    from third parties not affiliated with Toggles ("Third Party
                    Vendors"). By submitting a request of any kind, including
                    but not exclusive to Vendor Integration Requests,
                    Connections and Interactions through the Toggles Services
                    and Applications, you are giving to consent that all
                    requests are authorized by you and that a Vendor may, at
                    their discretion, perform all actions deemed necessary for
                    the performance of service including but not exclusive to
                    implementing vendor integration services into a live
                    production environment without any additional authorization
                    by you.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    All matters concerning such services desired from Third
                    Party Vendors, including but not limited to purchase terms,
                    payment terms, warranties, guarantees, license terms,
                    maintenance and delivery, are solely between the Third Party
                    Vendors and the User and should be directed to such
                    particular Third Party Vendor. Toggles makes no warranties
                    or representations whatsoever with regard to any such
                    services or merchandise provided by Third Party Vendors. The
                    User will not consider Toggles, nor will Toggles be
                    construed, as a party to, or a third party beneficiary of,
                    such transactions, whether or not Toggles may have received
                    revenue or other remuneration in connection with the
                    transaction. Toggles will not be liable to the User for any
                    costs or damages incurred by the User or any other person
                    with respect to or arising out of transactions with Third
                    Party Vendors.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Privacy Policy.</strong> It is the policy of Toggles
                    to respect the privacy of all Users. Your use of the Toggles
                    Services and Applications as well as certain other
                    information about you is subject to the terms and conditions
                    of our{" "}
                    <a href="https://www.gettoggles.com/privacy">
                        Privacy Policy
                    </a>
                    , which is incorporated into these Terms by reference.
                </Typography>

                <Typography variant="h4" className="mb-40 mt-80">
                    Questions
                </Typography>
                <Typography variant="body1" gutterBottom>
                    If you have any questions about this terms of use, please
                    feel free to{" "}
                    <a href="https://www.gettoggles.com/support">contact us</a>.
                </Typography>

                <Typography variant="body1" gutterBottom>
                    Last Update: September 22nd, 2024
                </Typography>
            </div>
        </Container>
    );
}
