// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Masonry from "@mui/lab/Masonry";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { HR_TEMPLATES } from "./templatedata";

import "./common.scss";

function Metadata() {
    return (
        <Helmet>
            <title>Toggles | Email Templates for Human Resources</title>
            <meta
                name="description"
                content="Free HR Email Templates: Streamline Onboarding, Interview Scheduling & More! Automate Tasks & Save Time with Toggles."
            />
            <meta
                itemProp="name"
                content="Toggles | Email Templates for Human Resources"
            />
            <meta
                itemProp="description"
                content="Free HR Email Templates: Streamline Onboarding, Interview Scheduling & More! Automate Tasks & Save Time with Toggles."
            />
            <meta
                itemProp="image"
                content="http://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta
                property="og:url"
                content="https://www.gettoggles.com/solutions/human-resources"
            />
            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content="Toggles | Email Templates for Human Resources"
            />
            <meta
                property="og:description"
                content="Free HR Email Templates: Streamline Onboarding, Interview Scheduling & More! Automate Tasks & Save Time with Toggles."
            />
            <meta
                property="og:image"
                content="https://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta name="twitter:site" content="@togglesforemail" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content="Toggles | Email Templates for Human Resources"
            />
            <meta
                name="twitter:description"
                content="Free HR Email Templates: Streamline Onboarding, Interview Scheduling & More! Automate Tasks & Save Time with Toggles."
            />
            <meta
                name="twitter:image"
                content="https://www.gettoggles.com/graphics/meta-twitter-image-1600x900.jpg"
            />
            <link
                rel="canonical"
                href="https://www.gettoggles.com/solutions/human-resources"
            />
        </Helmet>
    );
}

function capitalize(s) {
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

export default function HumanResources() {
    const [filteredTemplates, setFilteredTemplates] = useState(HR_TEMPLATES);
    const [availableCategories, setAvailableCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const handleCategorySelect = (category) => {
        if (selectedCategories.includes(category)) {
            setSelectedCategories(
                selectedCategories.filter((selected) => selected !== category)
            );
        } else {
            setSelectedCategories([...selectedCategories, category]);
        }
    };

    useEffect(() => {
        setFilteredTemplates(HR_TEMPLATES);
        setAvailableCategories(
            HR_TEMPLATES.reduce((acc, template) => {
                if (!acc.includes(template.category)) {
                    acc.push(template.category);
                }
                return acc;
            }, [])
        );
    }, []);

    useEffect(() => {
        if (selectedCategories.length === 0) {
            setFilteredTemplates(HR_TEMPLATES);
        } else {
            setFilteredTemplates(
                HR_TEMPLATES.filter((template) =>
                    selectedCategories.includes(template.category)
                )
            );
        }
    }, [selectedCategories]);

    return (
        <Container maxWidth="xl" className="template-page-container">
            <Metadata />

            <div className="hero">
                <Box className="hero-box">
                    <Typography
                        className="hero-title animated-color"
                        variant="h1"
                        align="center"
                    >
                        Free email templates
                        <br />
                        <span className="title-italic">for HR teams</span>.
                    </Typography>
                    <Typography
                        className="hero-description title-italic"
                        variant="h3"
                        align="center"
                    >
                        Streamline your HR processes with these free email
                        templates. From onboarding to benefits, we've got you
                        covered.
                    </Typography>
                </Box>
            </div>

            <div className="filter-chips">
                {availableCategories.map((category, index) => (
                    <Chip
                        className={`category-chip ${
                            selectedCategories.includes(category)
                                ? "selected"
                                : ""
                        }`}
                        key={`category-chip-${index}`}
                        variant="outlined"
                        label={capitalize(category)}
                        onClick={() => handleCategorySelect(category)}
                    />
                ))}
            </div>

            <Masonry
                columns={{ xs: 1, md: 2 }}
                spacing={4}
                sx={{ width: "auto" }}
            >
                {filteredTemplates.map((template, index) => (
                    <div className="template-card" key={`hr-template-${index}`}>
                        <div className="category">
                            <LocalOfferIcon className="icon" />
                            {capitalize(template.category)}
                        </div>
                        <div className="subject">
                            Subject:{" "}
                            <span className="italic">{template.subject}</span>
                        </div>
                        <div
                            className="body"
                            dangerouslySetInnerHTML={{
                                __html: template.body,
                            }}
                        ></div>
                        <div className="description">
                            <InfoOutlinedIcon className="icon" />
                            {template.description}
                        </div>
                    </div>
                ))}
            </Masonry>

            <div className="page-cta">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    <span className="animated-color">
                        Unlock email efficiency with Toggles
                    </span>
                </Typography>
                <Typography
                    className="section-description"
                    variant="h5"
                    align="center"
                >
                    Spend less time coordinating emails and more time focused on
                    what really matters.
                </Typography>
                <Button
                    className="cta-button"
                    variant="outlined"
                    size="large"
                    component={Link}
                    to={"/solutions/human-resources"}
                >
                    Learn More{" "}
                    <KeyboardArrowRightIcon className="cta-button-arrow" />
                </Button>
            </div>
        </Container>
    );
}
