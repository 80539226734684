import React from "react";
import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Demo from "../../components/Demo";
import outlookIcon from "../../assets/outlook-icon.png";
import recipientGroupsGraphic from "../../assets/hr-feature-recipients.png";
import multipleActionsGraphic from "../../assets/hr-feature-combine-actions.png";
import templatesFeatureGraphic from "../../assets/hr-feature-templates.png";
import signatureFeatureGraphic from "../../assets/hr-feature-signatures.png";

import "./HumanResources.scss";

function Metadata() {
    return (
        <Helmet>
            <title>Toggles | Productivity for HR Teams</title>
            <meta
                name="description"
                content="Toggles for Outlook automates repetitive tasks, streamlines communication, and boosts HR team productivity. Free Trial Available!"
            />
            <meta
                itemProp="name"
                content="Toggles | Productivity for HR Teams"
            />
            <meta
                itemProp="description"
                content="Toggles for Outlook automates repetitive tasks, streamlines communication, and boosts HR team productivity. Free Trial Available!"
            />
            <meta
                itemProp="image"
                content="http://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta
                property="og:url"
                content="https://www.gettoggles.com/solutions/human-resources"
            />
            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content="Toggles | Productivity for HR Teams"
            />
            <meta
                property="og:description"
                content="Toggles for Outlook automates repetitive tasks, streamlines communication, and boosts HR team productivity. Free Trial Available!"
            />
            <meta
                property="og:image"
                content="https://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta name="twitter:site" content="@togglesforemail" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content="Toggles | Productivity for HR Teams"
            />
            <meta
                name="twitter:description"
                content="Toggles for Outlook automates repetitive tasks, streamlines communication, and boosts HR team productivity. Free Trial Available!"
            />
            <meta
                name="twitter:image"
                content="https://www.gettoggles.com/graphics/meta-twitter-image-1600x900.jpg"
            />
            <link
                rel="canonical"
                href="https://www.gettoggles.com/solutions/human-resources"
            />
        </Helmet>
    );
}

export default function HumanResources() {
    return (
        <Container maxWidth="lg">
            <Metadata />

            {/* HERO */}
            <div className="hr-hero">
                <Box className="hero-box">
                    <Typography
                        className="hero-title animated-color"
                        variant="h1"
                        align="center"
                    >
                        Streamline Emails.
                        <br />
                        <span className="title-italic">Boost Productivity</span>
                        .
                    </Typography>
                    <Typography
                        className="hero-description title-italic"
                        variant="h3"
                        align="center"
                    >
                        Toggles empowers HR teams to{" "}
                        <span className="title-highlight">do more</span> in{" "}
                        <span className="title-highlight">less time</span>
                    </Typography>
                    <Button
                        className="hero-button"
                        variant="outlined"
                        size="large"
                        href="https://hub.togglesforemail.com/signup?type=business"
                        target="_blank"
                    >
                        <span className="animated-color">
                            Try Toggles for FREE
                        </span>
                    </Button>
                </Box>
            </div>

            <Grid className="hr-painpoints section" spacing={3} container>
                <Grid className="painpoint-box" xs={12} md={4}>
                    <Typography
                        className="painpoint-title"
                        variant="h3"
                        align="center"
                    >
                        <span className="animated-color">
                            Accomplish More — Faster
                        </span>
                    </Typography>
                    <Typography
                        className="painpoint-description"
                        variant="subtitle1"
                        align="center"
                    >
                        Send personalized emails with a couple clicks to
                        maximize your team's time.
                    </Typography>
                </Grid>
                <Grid className="painpoint-box" xs={12} md={4}>
                    <Typography
                        className="painpoint-title"
                        variant="h3"
                        align="center"
                    >
                        <span className="animated-color">
                            Deliver Consistent Messages
                        </span>
                    </Typography>
                    <Typography
                        className="painpoint-description"
                        variant="subtitle1"
                        align="center"
                    >
                        Coordinate templates, attachments and rules across your
                        team to streamline emails.
                    </Typography>
                </Grid>
                <Grid className="painpoint-box" xs={12} md={4}>
                    <Typography
                        className="painpoint-title"
                        variant="h3"
                        align="center"
                    >
                        <span className="animated-color">
                            Automate With Ease
                        </span>
                    </Typography>
                    <Typography
                        className="painpoint-description"
                        variant="subtitle1"
                        align="center"
                    >
                        Create standardized emails and workflows quickly without
                        being a tech whiz.
                    </Typography>
                </Grid>
            </Grid>

            <div className="hr-use-case-pitch section">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    <span className="animated-color">
                        Does your team need a productivity boost?
                    </span>
                </Typography>
                <Typography
                    className="section-description"
                    variant="h5"
                    align="center"
                >
                    Watch how Toggles speeds up email processes and increases
                    efficiency.
                </Typography>
                <Grid className="audience-video-container" xs={12} md={8}>
                    <video
                        className="audience-video"
                        playsInline
                        controls
                        preload="metadata"
                    >
                        <source
                            src="https://www.gettoggles.com/docs/hr-use-case-demo-short.mp4#t=0.001"
                            type="video/mp4"
                        />
                    </video>
                </Grid>
            </div>

            <div className="hr-demo section">
                <Typography
                    className="section-title fwidth"
                    variant="h2"
                    align="center"
                >
                    <span className="animated-color">
                        Switch a rule on to see
                    </span>{" "}
                    <br />
                    how HR teams use Toggles
                </Typography>

                <Demo
                    showTypeOptions={false}
                    userTypes={[
                        {
                            name: "hr",
                            icon: (
                                <Diversity3Icon className="audience-item-icon" />
                            ),
                        },
                    ]}
                />
            </div>

            {/* FEATURES */}
            {/* <div className="hr-features section">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    Unmatched features.{" "}
                    <span className="animated-color">Your rules.</span>
                </Typography>
                <Grid
                    className="features-grid"
                    container
                    rowSpacing={{ xs: 2, sm: 4, md: 6 }}
                    columnSpacing={{ xs: 2, sm: 4, md: 6 }}
                >
                    <Grid className="feature-box" xs={12} md={6}>
                        <div className="feature-box-container">
                            <img
                                className="feature-img"
                                src={templatesFeatureGraphic}
                                alt="Toggles Template Feature Graphic"
                            />
                            <Typography
                                className="feature-label"
                                variant="h3"
                                align="center"
                            >
                                Use templates and stop typing the same email
                                over and over
                            </Typography>
                        </div>
                    </Grid>
                    <Grid className="feature-box" xs={12} md={6}>
                        <div className="feature-box-container">
                            <img
                                className="feature-img"
                                src={signatureFeatureGraphic}
                                alt="Toggles Signature Feature Graphic"
                            />
                            <Typography
                                className="feature-label"
                                variant="h3"
                                align="center"
                            >
                                Keep your team's signatures consistent and up to
                                date
                            </Typography>
                        </div>
                    </Grid>
                    <Grid className="feature-box" xs={12} md={6}>
                        <div className="feature-box-container">
                            <img
                                className="feature-img"
                                src={recipientGroupsGraphic}
                                alt="Toggles Recipient Groups Graphic"
                            />
                            <Typography
                                className="feature-label"
                                variant="h3"
                                align="center"
                            >
                                Instantly add predefined recipient groups for
                                any email
                            </Typography>
                        </div>
                    </Grid>
                    <Grid className="feature-box" xs={12} md={6}>
                        <div className="feature-box-container">
                            <img
                                className="feature-img"
                                src={multipleActionsGraphic}
                                alt="Toggles Multiple Actions Feature Graphic"
                            />
                            <Typography
                                className="feature-label"
                                variant="h3"
                                align="center"
                            >
                                Combine actions to create time saving workflows
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </div> */}

            <div className="hr-cta">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    <span className="animated-color">
                        Unlock email efficiency
                    </span>
                </Typography>
                <Typography
                    className="section-description"
                    variant="h5"
                    align="center"
                >
                    Spend less time coordinating emails and more time focused on
                    what really matters.
                </Typography>
                <Button
                    className="cta-button"
                    variant="outlined"
                    size="large"
                    href="https://hub.togglesforemail.com/signup?type=business"
                    target="_blank"
                >
                    Try Toggles Today{" "}
                    <KeyboardArrowRightIcon className="cta-button-arrow" />
                </Button>
            </div>
        </Container>
    );
}
