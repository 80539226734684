import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";
import Layout from './Layout';
import Home from './pages/Home';
import BusinessPro from './pages/BusinessPro';
import Pricing from "./pages/Pricing";
import Support from './pages/Support';
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import NotFound from './pages/NotFound';
import RequestDemo from './pages/RequestDemo';
import HumanResourcesSolution from './pages/solutions/HumanResources';
import HumanResourcesTemplates from './pages/resources/templates/HumanResources';

// Initialize React Ga with your tracking ID
ReactGA.initialize("G-E90JF20ML5", {
    gaOptions: {
        debug_mode: window?.location?.host === 'www.gettoggles.com',
    },
    gtagOptions: {
        debug_mode: window?.location?.host === 'www.gettoggles.com',
    },
});

export default function App() {
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: location.pathname + location.search,
        });
    }, [location]);

    return (
        <HelmetProvider>
            <div className="app-container">
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path="teams" element={<BusinessPro />} />
                        <Route path="pricing" element={<Pricing />} />
                        <Route path="support" element={<Support />} />
                        <Route path="request-demo" element={<RequestDemo />} />
                        <Route path="privacy" element={<Privacy />} />
                        <Route path="terms" element={<Terms />} />

                        <Route path="solutions/">
                            <Route
                                path="human-resources"
                                element={<HumanResourcesSolution />}
                            />
                        </Route>

                        <Route path="resources/">
                            <Route path="templates/">
                                <Route
                                    path="human-resources"
                                    element={<HumanResourcesTemplates />}
                                />
                            </Route>
                        </Route>

                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </div>
        </HelmetProvider>
    );
}
