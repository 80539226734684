import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import "./styles.scss";

const ANNUAL_COST_PER_USER = 180;

export default function ROICalculator() {
    const [numEmployees, setNumEmployees] = useState(20);
    const [salary, setSalary] = useState(50000);
    const [hoursSaved, setHoursSaved] = useState(3);
    
    const getCostPerYear = () => {
        return numEmployees * ANNUAL_COST_PER_USER;
    };

    const getHoursSavedperYear = () => {
        return numEmployees * hoursSaved * 52;
    };

    const getSavingsPerYear = () => {
        return getHoursSavedperYear() * (salary / 52 / 40);
    };

    const getReplacedFTEs = () => {
        return (getHoursSavedperYear() / 2080);
    };

    const getROI = () => {
        return (getSavingsPerYear() / getCostPerYear()) * 100;
    };

    const salaryValueFormat = (value: number) => {
        return `$${value.toLocaleString()}`;
    };

    const hoursValueFormat = (value: number) => {
        return `${value} hrs`;
    };

    return (
        <div className="roi-calculator">
            <Container maxWidth="lg">
                <Grid container spacing={3} className="container">
                    <Grid xs={12} className="text-content roi-header">
                        <Typography variant="h2">
                            Discover your potential savings.
                        </Typography>
                        <Typography variant="h5" className="subtitle">
                            Use the calculator below to calculate the estimated
                            savings and ROI that your organization can gain by
                            using Toggles.
                        </Typography>
                    </Grid>
                    <Grid className="roi-form" xs={12} md={5}>
                        <div className="roi-input">
                            <Typography
                                className="label"
                                variant="h6"
                                gutterBottom
                            >
                                Number of employees
                            </Typography>
                            <Slider
                                className="slider"
                                aria-label="Number of employees"
                                value={numEmployees}
                                onChange={(e, value) => {
                                    setNumEmployees(value as number);
                                }}
                                valueLabelDisplay="on"
                                step={5}
                                min={5}
                                max={500}
                            />
                        </div>
                        <div className="roi-input">
                            <Typography
                                className="label"
                                variant="h6"
                                gutterBottom
                            >
                                Average annual salary
                            </Typography>
                            <Slider
                                className="slider"
                                aria-label="Number of employees"
                                value={salary}
                                onChange={(e, value) => {
                                    setSalary(value as number);
                                }}
                                valueLabelDisplay="on"
                                getAriaValueText={salaryValueFormat}
                                valueLabelFormat={salaryValueFormat}
                                step={5000}
                                min={25000}
                                max={250000}
                            />
                        </div>
                        <div className="roi-input">
                            <Typography
                                className="label"
                                variant="h6"
                                gutterBottom
                            >
                                Hours saved per week
                            </Typography>
                            <Slider
                                className="slider"
                                aria-label="Number of employees"
                                value={hoursSaved}
                                onChange={(e, value) => {
                                    setHoursSaved(value as number);
                                }}
                                valueLabelDisplay="on"
                                getAriaValueText={hoursValueFormat}
                                valueLabelFormat={hoursValueFormat}
                                step={0.5}
                                min={0.5}
                                max={20}
                            />
                        </div>
                        <Typography variant="body1" className="roi-disclaimer">
                            Toggles customers report saving anywhere between 1-8
                            hours per week for each employee using Toggles for
                            Outlook. This will depend on how many repetitive
                            emails a user sends and how much time they spend
                            composing each email.
                        </Typography>
                    </Grid>
                    <Grid className="roi-info" xs={12} md={7}>
                        <div className="roi-info-section">
                            <div className="roi-info-label large">
                                Annual ROI
                            </div>
                            <div className="roi-info-value large">
                                {Number(getROI().toFixed()).toLocaleString()}%
                            </div>
                        </div>
                        <div className="roi-info-section">
                            <div className="roi-info-label">
                                Projected hours saved per year
                            </div>
                            <div className="roi-info-value">
                                {getHoursSavedperYear().toLocaleString()}
                            </div>
                        </div>
                        <div className="roi-info-section">
                            <div className="roi-info-label">Projected producitivity benefits</div>
                            <div className="roi-info-value">
                                ${getSavingsPerYear().toLocaleString()}
                            </div>
                        </div>
                        <div className="roi-info-section">
                            <div className="roi-info-label">
                                FTE equivalent
                            </div>
                            <div className="roi-info-value">
                                {Number(
                                    getReplacedFTEs().toFixed(2)
                                ).toLocaleString()}
                            </div>
                        </div>
                        <Button
                            className="cta-button"
                            variant="contained"
                            size="large"
                            href="/request-demo"
                        >
                            Request a Demo
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

// ROI Calculator
// Hours / Week Saved
// # of users
// Average Annual Salary

// Toggles Cost / week
// Toggles Cost / year
// Savings / week
// Savings / year
// ROI
