import { Subject } from "@mui/icons-material";

export const HR_TEMPLATES = [
    {
        description:
            "A straightforward email outlining key details and logistics for the first day of a new hire.",
        subject: "Welcome to {{Company_Name}}",
        body: `
            <p>Dear <span class="variable">{{New_Employee_First_Name}}</span>,</p>
            <br />
            <p>We are excited to welcome you to our team on <span class="variable">{{New_Employee_Start_Date}}</span>. <span class="variable">{{HR_Manager_Name}}</span> will meet you in the lobby of our building at <span class="variable">{{New_Employee_Start_Time}}</span>. Please remember to bring your ID.</p>
            <br />
            <p>When you arrive, you’ll be shown your office/workstation and introduced to other members of your team. We want you to feel comfortable in your work environment so please let us know if there is anything specific that you need.</p>
            <br />
            <p>We look forward to working with you—welcome to the team!</p>
            <br />
            <p>Sincerely,</p>
            <p><span class="variable">{{me::Full_Name}}</span></p>
        `,
        date_created: "2024-06-05T16:04:15.900Z",
        category: "onboarding",
    },
    {
        description:
            "A detailed email outlining key details and logistics for the first day of a new hire.",
        subject: "Your journey with [COMPANY] begins!",
        body: `
            <p>Hi <span class="variable">{{New_Employee_First_Name}}</span>,</p>
            <br />
            <p>We are all excited that your first day is almost here!</p>
            <br />
            <p>Please try to relax and enjoy your first day. Here are the details for when you arrive:</p>
            <ul>
                <li>Your confirmed start date is <span class="variable">{{New_Employee_Start_Date}}</span> Please arrive at the building entrance at <span class="variable">{{New_Employee_Start_Time}}</span>. Once there, please press the buzzer for our company at the door.</li>
                <li>You’ll be met in reception by <span class="variable">{{HR_Manager_Name}}</span> – our <span class="variable">{{HR_MANAGER_TITLE}}</span>.</li>
                <li><span class="variable">{{HR_Manager_Name}}</span> will give you a tour of our offices. They will also show you the location of the bathroom and cafeteria.</li>
            </ul>
            <p>Could you please let us know three interesting facts about yourself? We’ll include your details in an announcement email to all of our employees. We will also prepare a first-day lunch for you; please let us know if you have any special dietary requirements.</p>
            <br />
            <p>To further ease your journey on the first day, below is a list of essential information for you:</p>
            <ul>
                <li>Our company address: [COMPANY ADDRESS]</li>
                <li>[PARKING DETAILS]</li>
            </ul>
            <p>Here is a complete itinerary of your first day with us:</p>
            <ul>
                <li>9am to 10am: Office tour and a coffee break</li>
                <li>10am to 11am: Meeting with your line manager</li>
                <li>11am to 12pm: Workstation orientation</li>
                <li>12pm to 1pm: Lunch</li>
                <li>1pm to 5pm: New employee training</li>
            </ul>
            <p>If you have further questions, please don’t hesitate to contact us. If you think you may be running late, please contact <span class="variable">{{HR_Manager_Name}}</span> at <span class="variable">{{HR_MANAGER_EMAIL}}</span>.</p>
            <br />
            <p>Best regards and good luck,</p>
            <p><span class="variable">{{me::Full_Name}}</span></p>
        `,
        date_created: "2024-06-05T16:04:15.900Z",
        category: "onboarding",
    },
    {
        description:
            "A detailed email outlining the job offer and benefits for a new hire.",
        subject: "[COMPANY] Job Offer!",
        body: `
            <p>Hi <span class="variable">{{New_Employee_First_Name}}</span>,</p>
            <br />
            <p>The whole company is pleased to welcome you to Toggles!</p>
            <br />
            <p>We want to thank you for completing our recruitment process. At every turn, you proved to us that you would add incredible value to our team.</p>
            <br />
            <p>As discussed with you over the phone, the summary of your offer is as follows:</p>
            <ul>
                <li>Job title: <span class="variable">{{New_Employee_Job_Title}}</span></li>
                <li>Department: <span class="variable">{{New_Employee_Deparment}}</span></li>
                <li>Your manager: <span class="variable">{{New_Employee_Manager_Name}}</span></li>
                <li>Your annual compensation: <span class="variable">{{New_Employee_Compensation}}</span></li>
            </ul>
            <br />
            <p>These are your other benefits:</p>
            <ul>
                <li>Stock options: <span class="variable">{{New_Employee_Num_Options}}</span></li>
                <li>Vacation details: <span class="variable">{{New_Employee_PTO_Days}}</span> days</li>
            </ul>
            <br />
            <p>We’re all excited about your first day! To help speed the process up, could you please complete the requested information in this workflow? There are documents to check and sign in the workflow, including your employment contract.</p>
            <br />
            <p>Once we receive your completed workflow, <span class="variable">{{HR_Manager_Name}}</span> – our <span class="variable">{{HR_MANAGER_TITLE}}</span> – will contact you by email to inform you of our employee onboarding process.</p>
            <br />
            <p>If you have further questions, please don’t hesitate to contact <span class="variable">{{HR_Manager_Name}}</span> on/at <span class="variable">{{HR_MANAGER_EMAIL}}</span>. We look forward to seeing you! </p>
            <br />
            <p>Best regards,</p>
            <p><span class="variable">{{me::Full_Name}}</span></p>
        `,
        date_created: "2024-06-05T16:04:15.900Z",
        category: "onboarding",
    },
    {
        description:
            "A warm welcome email to introduce a new hire to the team.",
        subject: "Welcome to {{New_Employee_Full_Name}} to the team!",
        body: `
            <p>Dear <span class="variable">{{New_Employee_Department}}</span> team,</p>
            <br />
            <p>I’m happy to inform you that <span class="variable">{{New_Employee_Full_Name}}</span> will join us on <span class="variable">{{New_Employee_Start_Date}}.</span> They will be working in <span class="variable">{{New_Employee_Department}},</span> and I’m sure you will all give them a friendly greeting!</p>
            <br />
            <p>First of all, here are some fascinating facts about our new hire:</p>
            <ul>
                <li>Fact one: <span class="variable">{{New_Employee_Fact1}}</</span>li>
                <li>Fact two: <span class="variable">{{New_Employee_Fact2}}</</span>li>
                <li>Fact three: <span class="variable">{{New_Employee_Fact3}}</</span>li>
            </ul>
            <p><span class="variable">{{New_Employee_First_Name}}</span> will be working in <span class="variable">{{New_Employee_Department}}</span> as our new <span class="variable">{{New_Employee_Job_Title}}.</span> Their manager will be <span class="variable">{{New_Employee_Manager}}.</</span>p>
            <br />
            <p>From 12pm to 1pm today, <span class="variable">{{New_Employee_First_Name}}</span> will take their lunch in the cafeteria. Please do introduce yourself and make them feel welcome.</p>
            <br />
            <p>I’m sure you will all wish <span class="variable">{{New_Employee_First_Name}}</span> the best of luck with their new position!</p>
            <br />
            <p>Thank you and best regards,</p>
            <p><span class="variable">{{me::Full_Name}}</span></p>
        `,
        date_created: "2024-06-05T16:04:15.900Z",
        category: "onboarding",
    },
    {
        description: "A follow-up email to request feedback from a new hire.",
        subject: "Onboarding feedback would be greatly appreciated!",
        body: `
            <p>Hi <span class="variable">{{New_Employee_First_Name}}</span>,</p>
            <br />
            <p>It’s hard to believe that you’ve worked with us for a week!</p>
            <br />
            <p>Thank you for all your hard work so far. It was great to meet you on your first day, and I hope you enjoyed the lunch we put on for you.</p>
            <br />
            <p>We want to ask for a favor, if we may? Could you please answer the following questions for us? Please be honest in your feedback. If you feel there are other issues you’d like to discuss, please don’t hesitate to mention them.</p>
            <ul>
                <li>On your first day of work, did you feel we missed anything?</li>
                <li>How do you feel about your time with the company so far?</li>
                <li>Would you like to tell us about any issues or observations?</li>
            </ul>
            <p>We look forward to seeing your feedback.</p>
            <br />
            <p>Enjoy your day and best regards,</p>
            <p><span class="variable">{{me::Full_Name}}</span></p>
        `,
        date_created: "2024-06-05T16:04:15.900Z",
        category: "onboarding",
    },
    {
        description:
            "A professional email from HR expressing regret for the employee's departure and outlining next steps.",
        subject: "Thank You and Best Wishes",
        body: `
            <p> Dear <span class="variable">{{Employee_Name}}</span>,</p>
            <br />
            <p>The HR team at Toggles is writing to acknowledge your resignation. While we understand your reasons for leaving, we're sorry to see you go.</p>
            <br />
            <p>We appreciate the invaluable work you have done throughout your time with [COMPANY NAME].</p>
            <br />
            <p>To ensure a smooth transition, we'd like to discuss the handover of your projects and responsibilities. Your manager will be in touch to schedule a meeting in the coming days.</p>
            <br />
            <p>In the meantime, here are some next steps for the offboarding process:</p>
            <ul>
                <li>Return all company property (laptop, badge, etc.) to the IT department by <span class="variable">{{Offboard_Equipment_Date}}</span>.</li>
                <li>You will receive your final paycheck on <span class="variable">{{Offboard_Final_Pay_Date}}</span> according to your regular pay schedule.</li>
            </ul>
            <p>We wish you all the best in your future endeavors. Please keep in touch!</p>
            <br />
            <p>Sincerely,</p>
            <p><span class="variable">{{me::Full_Name}}</span></p>
        `,
        date_created: "2024-06-05T16:04:15.900Z",
        category: "offboarding",
    },
    {
        description:
            "A cold email template HR can use when sourcing passive candidates for an open position or trying to hire for hard-to-fill roles in your company",
        subject: "[COMPANY NAME] is looking for a {{Open_Job_Title}}",
        body: `
            <p>Hi <span class="variable">{{Candidate_First_Name}}</span>,</p>
            <br />
            <p>I am <span class="variable">{{me::Full_Name}}</span>, <span class="variable">{{me::Job_Title}}</span> at [COMPANY NAME]. I saw your profile on <span class="variable">{{Source_Site}}</span> and I was really impressed by your experience in <span class="variable">{{Candidate_Skill_Or_Achievement}}</span>.</p>
            <br />
            <p>We are currently looking for a <span class="variable">{{Open_Job_Title}}</span> to join our team. I’d love to tell you a little more about this position and learn a few things about you, as well. Are you available <span class="variable">{{Available_DateTime1}}</span> or <span class="variable">{{Available_DateTime2}}</span>? If so, I’d be happy to set up a call. I’m also happy to coordinate via email or LinkedIn, if you prefer.</p>
            <br />
            <p>I hope you have a great day,</p>
            <p><span class="variable">{{me::Full_Name}}</span></p>
        `,
        date_created: "2024-06-05T16:04:15.900Z",
        category: "interviewing",
    },
    {
        description:
            "A professional email inviting a candidate to a phone interview.",
        subject: "Invitation to phone interview – [COMPANY NAME] ",
        body: `
            <p>Hi <span class="variable">{{Candidate_First_Name}},</span></p>
            <br />
            <p>Thank you for applying to [COMPANY NAME].</p>
            <br />
            <p>My name is <span class="variable">{{me::Full_Name}}</span> and I’m a {{me::Job_Title}} at [COMPANY NAME]. I would like to have a phone discussion about your application for the <span class="variable">{{Open_Job_Title}}</span> role.</p>
            <br />
            <p>I’d like to tell you more about [COMPANY NAME] and get to know you a bit better.</p>
            <br />
            <p>Would you be available for a short introductory phone call <span class="variable">{{Interview_Proposed_Date1}}</span>?</p>
            <br />
            <p>Looking forward to hearing from you,</p>
            <p><span class="variable">{{me::Full_Name}}</span></p>
        `,
        date_created: "2024-06-05T16:04:15.900Z",
        category: "interviewing",
    },
    {
        description:
            "A professional email notifying a candidate of a take-home assignment.",
        subject: "Assignment for the {{Open_Job_Title}} position",
        body: `
            <p>Hi <span class="variable">{{Candidate_First_Name}},</span></p>
            <br />
            <p>Please find attached an assignment as part of our interview process. I’m also attaching some instructions to help you complete the assignment.</p>
            <br />
            <p>Keep in mind that there are no ‘right answers.’ This assignment is designed is to gauge your skills and give us an idea of how you approach tasks relevant to the <span class="variable">{{Open_Job_Title}}</span> role. It would be great if you could send this over to us by <span class="variable">{{Assignment_Deadline}}</span>.</p>
            <br />
            <p>Please do not hesitate to get in touch if you have any questions.</p>
            <br />
            <p>Best of luck with the assignment,</p>
            <p><span class="variable">{{me::Full_Name}}</span></p>
        `,
        date_created: "2024-06-05T16:04:15.900Z",
        category: "interviewing",
    },
    {
        description:
            "A professional email thanking a candidate for completing a take-home assignment.",
        subject: "[REPLY TO THEIR EMAIL]",
        body: `
            <p>Hi <span class="variable">{{Candidate_First_Name}},</span></p>
            <br />
            <p>Thank you for completing the assignment. Our team will review it and get back to you with feedback as soon as possible.</p>
            <br />
            <p>I hope you enjoy your day,</p>
            <p><span class="variable">{{me::Full_Name}}</span></p>
        `,
        date_created: "2024-06-05T16:04:15.900Z",
        category: "interviewing",
    },
    {
        description:
            "A professional email inviting a candidate to an in-person/video/call interview.",
        subject: "Invitation to interview – [COMPANY NAME] ",
        body: `
            <p>Hi <span class="variable">{{Candidate_First_Name}},</span></p>
            <br />
            <p>Thank you for applying to [COMPANY NAME].</p>
            <br />
            <p>Your application for the <span class="variable">{{Open_Job_Title}}</span> position stood out to us and we would like to invite you for an interview at our office[s] to get to know you a bit better.</p>
            <br />
            <p>You will meet with the <span class="variable">{{Job_Department}}</span> department manager <span class="variable">{{Interviewing_Manager_Name}}</span>. The interview will last about <span class="variable">{{Interview_Length_Mins}}</span> minutes and you’ll have the chance to discuss the <span class="variable">{{Open_Job_Title}}</span> position and learn more about our company.</p>
            <br />
            <p>Would you be available on <span class="variable">{{Interview_Proposed_DateTime1}}</span> or <span class="variable">{{Interview_Proposed_DateTime2}}</span>?</p>
            <br />
            <p>Looking forward to hearing from you,</p>
            <br />
            <p>Kind Regards,</p>
            <p><span class="variable">{{me::Full_Name}}</span></p>
        `,
        date_created: "2024-06-05T16:04:15.900Z",
        category: "interviewing",
    },
    {
        description:
            "A professional email template to reject a candidate after an interview.",
        subject:
            "Application for the {{Open_Job_Title}} role at [COMPANY NAME]",
        body: `
            <p>Dear <span class="variable">{{Candidate_First_Name}},</span></p>
            <br />
            <p>Thank you for taking the time to meet with our team about the {{Open_Job_Title}} role at [COMPANY NAME]. It was a pleasure to learn more about your skills and accomplishments.<p>
            <br />
            <p>Unfortunately, our team did not select you for further consideration.<p>
            <br />
            <p>I would like to note that competition for jobs at [COMPANY NAME] is always strong and that we often have to make difficult choices between many high-caliber candidates. Now that we’ve had the chance to know more about you, we will be keeping your resume on file for future openings that better fit your profile.<p>
            <br />
            <p>I am happy to answer your questions if you would like any specific feedback about your application or interviews.<p>
            <br />
            <p>Thanks again for your interest in [COMPANY NAME] and best of luck with your job search.<p>
            <br />
            <p>Regards,<p>
            <p><span class="variable">{{me::Full_Name}}</span></p>
        `,
        date_created: "2024-06-05T16:04:15.900Z",
        category: "interviewing",
    },
    {
        description: "An internal email template to announce open enrollment for benefits.",
        subject: "Employee Benefits Enrollment Invitation",
        body: `
            <p>Dear <span class="variable">{{Employee_First_Name}}</span>,</p>
            <br />
            <p>We are pleased to announce that it’s time for our annual employee benefits enrollment period. This year, we offer a wide range of benefits to help you take care of yourself and your family.</p>
            <br />
            <p>Please take a moment to review the benefits package that we have put together for you. You can find all of the information you need on our website.</p>
            <br />
            <p>If you have any questions about the enrollment process or any of our benefits, please don’t hesitate to contact us.</p>
            <br />
            <p>Thank you for your continued hard work and dedication.</p>
            <br />
            <p>Sincerely,</p>
            <p>The HR Team</p>
        `,
        date_created: "2024-06-05T16:04:15.900Z",
        category: "benefits",
    },
    {
        description:
            "An email template to invite employees to a training session.",
        subject:
            "Training invitation: {{Training_Subject_Title}}. Save the date!",
        body: `
            <p>Hi Team!</p>
            <br />
            <p>We'd like to invite you to a <span class="variable">{{Training_Subject_Title}}</span> training session on <span class="variable">{{Training_Date}}</span>, from <span class="variable">{{Training_Start_Time}}</span> to <span class="variable">{{Training_End_Time}}</span>. Please mark this date on your calendar.</p>
            <br />
            <p>The training will be held at <span class="variable">{{Training_Location}}</span> and will be led by <span class="variable">{{Training_Instructor}}</span>, who will provide <span class="variable">{{Training_description}}</span>.</p>
            <br />
            <p>We require all employees to attend. The goal of this training is to <span class="variable">{{Training_Goals}}</span>. After attending this session, you’ll have learned how to <span class="variable">{{Training_Skills_Obtained}}</span>.</p>
            <br />
            <p>If you have any special needs or requirements, please let me know.</p>
            <br />
            <p>We look forward to seeing you there.</p>
            <br />
            <p>Best regards,</p>
            <p>The HR Team</p>
        `,
        date_created: "2024-06-05T16:04:15.900Z",
        category: "training",
    },
    {
        description: "An email template to announce a new or updated company policy.",
        subject: "Policy Update: Changes to our {{Policy_Name}} Policy",
        body: `
            <p>Dear <span class="variable">{{Employee_First_Name}}</span>,</p>
            <br />
            <p>We're writing to inform you about some important changes to our <span class="variable">{{Policy_Name}}</span> Policy, effective from <span class="variable">{{Policy_Effective_Date}}</span>. Please take a moment to review these updates.</p>
            <br />
            <p>The revised policy includes <span class="variable">{{Policy_Changes_Info}}</span>. This decision was made after <span class="variable">{{Policy_Change_Reason}}</span>.</p>
            <br />
            <p>It's essential for all employees to familiarize themselves with these changes. We believe that these updates will <span class="variable">{{Policy_Change_Benefits}}</span>.</p>
            <br />
            <p>If you have any questions or need further clarification, please don't hesitate to reach out.</p>
            <br />
            <p>Thank you for your understanding and cooperation.</p>
            <br />
            <p>Warm regards,</p>
            <p>The HR Team</p>
        `,
        date_created: "2024-06-05T16:04:15.900Z",
        category: "announcement",
    },
];

export default {
    HR_TEMPLATES,
};
