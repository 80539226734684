import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import CampaignIcon from "@mui/icons-material/Campaign";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import GavelIcon from "@mui/icons-material/Gavel";
import Groups3Icon from "@mui/icons-material/Groups3";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import Demo from "../components/Demo";
import outlookIcon from "../assets/outlook-icon.png";
import subjectTagsGraphic from "../assets/subject-tag-feature2.png";
import recipientGroupsGraphic from "../assets/recipients-feature.png";
import attachmentsFeature from "../assets/attachments-feature.png";
import multipleActionsGraphic from "../assets/multiple-actions-feature.png";
import templatesFeatureGraphic from "../assets/templates-feature.png";
import signatureFeatureGraphic from "../assets/signature-feature.png";

import "./Home.scss";

function Metadata() {
    return (
        <Helmet>
            <title>Toggles | Supercharge Your Email Productivity</title>
            <meta
                name="description"
                content="Transform your email experience with Toggles. Streamline your workflow, automate tasks, and boost productivity with one-click toggles. Get started today!"
            />
            <meta itemProp="name" content="Toggles | Supercharge Your Email Productivity" />
            <meta itemProp="description" content="Transform your email experience with Toggles. Streamline your workflow, automate tasks, and boost productivity with one-click toggles. Get started today!" />
            <meta itemProp="image" content="http://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg" />
            <meta property="og:url" content="https://www.gettoggles.com" />
            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content="Toggles | Supercharge Your Email Productivity"
            />
            <meta
                property="og:description"
                content="Transform your email experience with Toggles. Streamline your workflow, automate tasks, and boost productivity with one-click toggles. Get started today!"
            />
            <meta
                property="og:image"
                content="https://www.gettoggles.com/graphics/meta-fb-image-1200x630.jpg"
            />
            <meta name="twitter:site" content="@togglesforemail" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content="Toggles | Supercharge Your Email Productivity"
            />
            <meta
                name="twitter:description"
                content="Transform your email experience with Toggles. Streamline your workflow, automate tasks, and boost productivity with one-click toggles. Get started today!"
            />
            <meta
                name="twitter:image"
                content="https://www.gettoggles.com/graphics/meta-twitter-image-1600x900.jpg"
            />
            <link rel="canonical" href="https://www.gettoggles.com/" />
        </Helmet>
    );
}

export default function Home() {    
    return (
        <Container maxWidth="lg">
            <Metadata />

            {/* HERO */}
            <div className="home-hero">
                <Box className="hero-box">
                    <Typography
                        className="hero-title animated-color"
                        variant="h1"
                        align="center"
                    >
                        Supercharge your email efficiency
                    </Typography>
                    <Button
                        className="hero-button"
                        variant="outlined"
                        size="large"
                        href="https://hub.togglesforemail.com/signup?type=premium"
                    >
                        <span className="animated-color">
                            Try Toggles Premium FREE
                        </span>
                    </Button>
                </Box>
            </div>

            <Demo />

            {/* FEATURES */}
            <div className="home-features section full-width">
                <Container maxWidth="xl">
                    <Typography
                        className="section-title"
                        variant="h2"
                        align="center"
                    >
                        Unmatched features.{" "}
                        <span className="animated-color">Your rules.</span>
                    </Typography>
                    <Grid
                        className="features-grid"
                        container
                        rowSpacing={{ xs: 2, sm: 4, md: 6 }}
                        columnSpacing={{ xs: 2, sm: 4, md: 6 }}
                    >
                        <Grid className="feature-box" xs={12} md={6} xl={4}>
                            <div className="feature-box-container">
                                <img
                                    className="feature-img"
                                    src={templatesFeatureGraphic}
                                    alt="Toggles Template Feature Graphic"
                                />
                                <Typography
                                    className="feature-label"
                                    variant="h3"
                                    align="center"
                                >
                                    Use templates and stop typing the same email
                                    over and over
                                </Typography>
                            </div>
                        </Grid>
                        <Grid className="feature-box" xs={12} md={6} xl={4}>
                            <div className="feature-box-container">
                                <img
                                    className="feature-img"
                                    src={signatureFeatureGraphic}
                                    alt="Toggles Signature Feature Graphic"
                                />
                                <Typography
                                    className="feature-label"
                                    variant="h3"
                                    align="center"
                                >
                                    Quickly insert your custom signature with a
                                    simple toggle
                                </Typography>
                            </div>
                        </Grid>
                        <Grid className="feature-box" xs={12} md={6} xl={4}>
                            <div className="feature-box-container">
                                <img
                                    className="feature-img"
                                    src={subjectTagsGraphic}
                                    alt="Toggles Tagging Feature Graphic"
                                />
                                <Typography
                                    className="feature-label"
                                    variant="h3"
                                    align="center"
                                >
                                    Add tags to the subject with the flip of a
                                    toggle
                                </Typography>
                            </div>
                        </Grid>
                        <Grid className="feature-box" xs={12} md={6} xl={4}>
                            <div className="feature-box-container">
                                <img
                                    className="feature-img"
                                    src={recipientGroupsGraphic}
                                    alt="Toggles Recipient Groups Graphic"
                                />
                                <Typography
                                    className="feature-label"
                                    variant="h3"
                                    align="center"
                                >
                                    Instantly add predefined recipient groups
                                    for any email
                                </Typography>
                            </div>
                        </Grid>
                        <Grid className="feature-box" xs={12} md={6} xl={4}>
                            <div className="feature-box-container">
                                <img
                                    className="feature-img"
                                    src={attachmentsFeature}
                                    alt="Toggles Attachments Feature Graphic"
                                />
                                <Typography
                                    className="feature-label"
                                    variant="h3"
                                    align="center"
                                >
                                    Attach common documents with just a toggle
                                </Typography>
                            </div>
                        </Grid>
                        <Grid className="feature-box" xs={12} md={6} xl={4}>
                            <div className="feature-box-container">
                                <img
                                    className="feature-img"
                                    src={multipleActionsGraphic}
                                    alt="Toggles Multiple Actions Feature Graphic"
                                />
                                <Typography
                                    className="feature-label"
                                    variant="h3"
                                    align="center"
                                >
                                    Combine actions to create time saving
                                    workflows
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="section full-width use-cases">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    <span className="animated-color">Productivity</span> for
                    those who want more.
                </Typography>
                <Container maxWidth="xl">
                    <Grid
                        container
                        spacing={6}
                        className="container"
                        alignItems="stretch"
                    >
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: '40px' }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <MonetizationOnOutlinedIcon
                                        sx={{ fontSize: "4.5rem" }}
                                    />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Sales
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Automate follow-ups, proposals, and
                                        client outreach to boost productivity,
                                        close deals faster, and focus on
                                        building relationships.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: '40px' }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <SupportAgentOutlinedIcon
                                        sx={{ fontSize: "4.5rem" }}
                                    />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Customer Support
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Respond to repetitive inquiries, send
                                        case updates, and improve response times
                                        by automating routine emails and
                                        communications.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: '40px' }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <Diversity3Icon
                                        sx={{ fontSize: "4.5rem" }}
                                    />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Human Resources
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Streamline employee onboarding,
                                        interview scheduling, and policy updates
                                        with automated emails, saving time and
                                        reducing manual tasks.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: '40px' }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <CampaignIcon sx={{ fontSize: "4.5rem" }} />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Marketing
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Automate email campaigns, follow-ups,
                                        and newsletters to streamline your
                                        communication strategy and free up time
                                        for creative tasks.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: '40px' }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <GavelIcon sx={{ fontSize: "4.5rem" }} />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Legal
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Reduce time spent on case updates,
                                        contract renewals, and compliance
                                        reminders by automating routine legal
                                        communication.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: '40px' }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <Groups3Icon sx={{ fontSize: "4.5rem" }} />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Management
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Automate project updates, task
                                        reminders, and meeting invitations,
                                        helping you manage teams and projects
                                        with increased efficiency.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: '40px' }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <ManageAccountsIcon
                                        sx={{ fontSize: "4.5rem" }}
                                    />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        IT & Tech Support
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Simplify IT notifications, system
                                        updates, and support communications by
                                        automating repetitive emails, allowing
                                        more focus on complex tasks.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            lg={3}
                            style={{ display: "flex" }}
                            className="use-case"
                        >
                            <Card raised sx={{ borderRadius: '40px' }}>
                                <Avatar
                                    sx={{
                                        bgcolor: "#1b568e",
                                        width: "120px",
                                        height: "120px",
                                        margin: "30px auto 10px auto",
                                    }}
                                >
                                    <LightbulbOutlinedIcon
                                        sx={{ fontSize: "4.5rem" }}
                                    />
                                </Avatar>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        textAlign="center"
                                    >
                                        Consulting
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >
                                        Automate client check-ins, status
                                        updates, and proposal deliveries to
                                        maintain consistent communication and
                                        improve client satisfaction.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            {/* <div className="home-use-cases section">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    <span className="animated-color">Productivity.</span> For
                    those who want more.
                </Typography>
                <Grid className="audience-section" container>
                    <Grid className="audience-item-container" xs={12} md={4}>
                        <SupportAgentOutlinedIcon className="audience-item-icon" />
                        <Typography
                            className="audience-item-label"
                            variant="h3"
                            align="center"
                        >
                            <span className="audience-type-label">Support</span>
                            <br />
                            Teams
                        </Typography>
                    </Grid>
                    <Grid className="audience-video-container" xs={12} md={8}>
                        <video
                            className="audience-video"
                            autoPlay
                            loop
                            muted
                            playsInline
                            controls
                        >
                            <source
                                src="https://www.gettoggles.com/docs/use-case-support.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </Grid>
                </Grid>
                <Grid className="audience-section" container>
                    <Grid className="audience-item-container" xs={12} md={4}>
                        <Diversity3Icon className="audience-item-icon" />
                        <Typography
                            className="audience-item-label"
                            variant="h3"
                            align="center"
                        >
                            <span className="audience-type-label">
                                Human Resource
                            </span>
                            <br />
                            Teams
                        </Typography>
                    </Grid>
                    <Grid className="audience-video-container" xs={12} md={8}>
                        <video
                            className="audience-video"
                            autoPlay
                            loop
                            muted
                            playsInline
                            controls
                        >
                            <source
                                src="https://www.gettoggles.com/docs/use-case-hr.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </Grid>
                </Grid>
                <Grid className="audience-section" container>
                    <Grid className="audience-item-container" xs={12} md={4}>
                        <MonetizationOnOutlinedIcon className="audience-item-icon" />
                        <Typography
                            className="audience-item-label"
                            variant="h3"
                            align="center"
                        >
                            <span className="audience-type-label">Sales</span>
                            <br />
                            Teams
                        </Typography>
                    </Grid>
                    <Grid className="audience-video-container" xs={12} md={8}>
                        <video
                            className="audience-video"
                            autoPlay
                            loop
                            muted
                            playsInline
                            controls
                        >
                            <source
                                src="https://www.gettoggles.com/docs/use-case-sales.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </Grid>
                </Grid>
                <Grid className="audience-section" container>
                    <Grid className="audience-item-container" xs={12} md={4}>
                        <LightbulbOutlinedIcon className="audience-item-icon" />
                        <Typography
                            className="audience-item-label"
                            variant="h3"
                            align="center"
                        >
                            <span className="audience-type-label">Project</span>
                            <br />
                            Teams
                        </Typography>
                    </Grid>
                    <Grid className="audience-video-container" xs={12} md={8}>
                        <video
                            className="audience-video"
                            autoPlay
                            loop
                            muted
                            playsInline
                            controls
                        >
                            <source
                                src="https://www.gettoggles.com/docs/use-case-project-team.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </Grid>
                </Grid>
                <Grid className="audience-section" container>
                    <Grid className="audience-item-container" xs={12} md={4}>
                        <SecurityOutlinedIcon className="audience-item-icon" />
                        <Typography
                            className="audience-item-label"
                            variant="h3"
                            align="center"
                        >
                            <span className="audience-type-label">
                                Security
                            </span>
                            <br />
                            Teams
                        </Typography>
                    </Grid>
                    <Grid className="audience-video-container" xs={12} md={8}>
                        <video
                            className="audience-video"
                            autoPlay
                            loop
                            muted
                            playsInline
                            controls
                        >
                            <source
                                src="https://www.gettoggles.com/docs/use-case-security.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </Grid>
                </Grid>
            </div> */}

            <div className="home-cta">
                <Typography
                    className="section-title"
                    variant="h2"
                    align="center"
                >
                    Unlock email efficiency
                </Typography>
                <Typography
                    className="section-description"
                    variant="h5"
                    align="center"
                >
                    Spend less time coordinating emails and more time focused on
                    what really matters.
                </Typography>
                <Button
                    className="cta-button"
                    variant="outlined"
                    size="large"
                    href="https://hub.togglesforemail.com/signup?type=premium"
                >
                    Get started today{" "}
                    <KeyboardArrowRightIcon className="cta-button-arrow" />
                </Button>
            </div>
        </Container>
    );
};
